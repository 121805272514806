import { useSelector } from "react-redux";
import { Navigate, useLocation } from "react-router-dom";
import { Layout } from "../components/layout";
import Cookies from "universal-cookie";

const PrivateRoute = ({ children }) => {
  let isAuth = "";
  const location=useLocation();
  const cookies = new Cookies();
  isAuth = cookies.get("token");
  const { pathname } = useLocation();
  const returnUrl=pathname

  if (isAuth == undefined) {
    const urlWithReturnUrl=`/login?returnURL=${returnUrl}`
    return <Navigate to={urlWithReturnUrl} state={{from: location,search: pathname}} />;
  }
  return <Layout>{children}</Layout>;
};

export default PrivateRoute;
