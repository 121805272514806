import { Helmet } from "react-helmet"
import { PieChart } from "../../components/charts/pieChart"
import { BarChart } from "../../components/charts/barChart"
import { useEffect, useState } from "react"
import { resolveRequest } from "../../helpers"
import { adminDashboardNumbers, adminDashboardReservationsChart, adminDashboardReviewsChart } from "../../apis/dashboard/adminDashboard"
import { getLang } from "../../helpers/localizationHelper"
import { InfinitySpin } from 'react-loader-spinner'
import { useTranslation } from "react-i18next"
import { tenantManagerDashboardNumbers, tenantManagerDashboardReservationsChart, tenantManagerDashboardReviewsChart } from "../../apis/dashboard/tenantManagerDashboard"
import { branchManagerDashboardNumbers, branchManagerDashboardReservationsChart, branchManagerDashboardReviewsChart } from "../../apis/dashboard/branchManagerDashboard"
import { UserAllData } from "../../apis/UserProfile"
import { useQuery } from "react-query"

export const AdminDashboardScreen = () => {
    const { t } = useTranslation()
    //admin
    const [isLoadingNumbers, setIsLoadingNumbers] = useState(false)

    const [adminNumbers, setAdminNumbers] = useState<any>()
    const [isLoadingReviews, setIsLoadingReviews] = useState(false)
    const [reviewsChart, setReviewsChart] = useState<any>()


    const [isLoadingReservationsLoading, setIsReservationsLoading] = useState(false)
    const [reservationsChart, setReservationsChart] = useState<any>()
    const fetchUserData = async () => {
        /*         return await UserMenu()
         */
        return await UserAllData()
    }
    const { isLoading, isError, error, data } = useQuery(['userAllData'], fetchUserData, {
        refetchInterval: 60000
    })
    useEffect(() => {
        if (data?.data?.roles?.includes("Admin")) {
            setIsLoadingNumbers(true)
            resolveRequest(adminDashboardNumbers, setAdminNumbers)
            setIsLoadingNumbers(false)
        }
    }, [getLang(), data])
    useEffect(() => {
        if (data?.data?.roles?.includes("Admin")) {

            setIsReservationsLoading(true)
            resolveRequest(adminDashboardReservationsChart, setReservationsChart)
            setIsReservationsLoading(false)
        }

    }, [getLang(), data])
    useEffect(() => {
        if (data?.data?.roles?.includes("Admin")) {

            setIsLoadingReviews(true)
            resolveRequest(adminDashboardReviewsChart, setReviewsChart)
            setIsLoadingReviews(false)
        }
    }, [getLang(), data])

    //tenant
    const [isLoadingTenantNumbers, setIsLoadingTenantNumbers] = useState(false)

    const [adminTenantNumbers, setAdminTenantNumbers] = useState<any>()
    const [isLoadingTenantReviews, setIsLoadingTenantReviews] = useState(false)
    const [reviewsTenantChart, setReviewsTenantChart] = useState<any>()


    const [isLoadingReservationsTenantLoading, setIsReservationsTenantLoading] = useState(false)
    const [reservationsTenantChart, setReservationsTenantChart] = useState<any>()

    useEffect(() => {
        if (data?.data?.roles?.includes("TenantManager")) {

            setIsLoadingTenantNumbers(true)
            resolveRequest(tenantManagerDashboardNumbers, setAdminTenantNumbers)
            setIsLoadingTenantNumbers(false)
        }
    }, [getLang(), data])
    useEffect(() => {
        if (data?.data?.roles?.includes("TenantManager")) {

            setIsReservationsTenantLoading(true)
            resolveRequest(tenantManagerDashboardReservationsChart, setReservationsTenantChart)
            setIsReservationsTenantLoading(false)
        }
    }, [getLang(), data])
    useEffect(() => {
        if (data?.data?.roles?.includes("TenantManager")) {

            setIsLoadingTenantReviews(true)
            resolveRequest(tenantManagerDashboardReviewsChart, setReviewsTenantChart)
            setIsLoadingTenantReviews(false)
        }
    }, [getLang(), data])

    //branch

    //tenant
    const [isLoadingBranchNumbers, setIsLoadingBranchNumbers] = useState(false)

    const [adminBranchNumbers, setAdminBranchNumbers] = useState<any>()
    const [isLoadingBranchReviews, setIsLoadingBranchReviews] = useState(false)
    const [reviewsBranchChart, setReviewsBranchChart] = useState<any>()


    const [isLoadingReservationsBranchLoading, setIsReservationsBranchLoading] = useState(false)
    const [reservationsBranchChart, setReservationsBranchChart] = useState<any>()

    useEffect(() => {
        if (data?.data?.roles?.includes("BranchManager")) {

            setIsLoadingBranchNumbers(true)
            resolveRequest(branchManagerDashboardNumbers, setAdminBranchNumbers)
            setIsLoadingBranchNumbers(false)
        }
    }, [getLang(), data])
    useEffect(() => {
        if (data?.data?.roles?.includes("BranchManager")) {

            setIsReservationsBranchLoading(true)
            resolveRequest(branchManagerDashboardReservationsChart, setReservationsBranchChart)
            setIsReservationsBranchLoading(false)
        }
    }, [getLang(), data])
    useEffect(() => {
        if (data?.data?.roles?.includes("BranchManager")) {

            setIsLoadingBranchReviews(true)
            resolveRequest(branchManagerDashboardReviewsChart, setReviewsBranchChart)
            setIsLoadingBranchReviews(false)
        }
    }, [getLang(), data])

    return <>


        {isLoading == false ? < div className="container" >
            <div className="row">
                {(data?.data?.roles?.includes("Admin")) ? <>
                    <>
                        {isLoadingNumbers == false && adminNumbers ? <>
                            <div className="col-xl-3 col-sm-6">
                                <div className="card">
                                    <div className="card-body d-flex justify-content-between">
                                        <div className="card-menu">
                                            <span>{t("Total Tenants")}</span>
                                            <h2 className="mb-0">{adminNumbers?.totalTenants}</h2>
                                        </div>
                                        <div className="icon-box icon-box-lg bg-primary-light">
                                            <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 8.7773C0.00165391 10.5563 1.44323 11.9981 3.2222 12H8.7778C10.5568 11.9981 11.9983 10.5563 12 8.7773V3.2227C11.9983 1.44373 10.5568 0.00192952 8.7778 0H3.2222C1.44323 0.00192952 0.00165319 1.44373 0 3.2227V8.7773ZM2 3.2227C2.00072 2.54791 2.54741 2.00099 3.2222 2L8.7778 2C9.45259 2.00099 9.99928 2.54791 10 3.2227V8.7773C9.99929 9.45209 9.45259 9.99901 8.7778 10L3.2222 10C2.54741 9.99901 2.00072 9.45209 2 8.7773V3.2227Z" fill="var(--primary)" />
                                                <path d="M0 22.7773C0.00165272 24.5563 1.44323 25.9981 3.2222 26L8.7778 26C10.5568 25.9981 11.9983 24.5563 12 22.7773V17.2227C11.9983 15.4437 10.5568 14.0019 8.7778 14L3.2222 14C1.44323 14.0019 0.00165391 15.4437 0 17.2227V22.7773ZM2 17.2227C2.00072 16.5479 2.54741 16.001 3.2222 16H8.7778C9.45259 16.001 9.99928 16.5479 10 17.2227V22.7773C9.99929 23.4521 9.45259 23.999 8.7778 24L3.2222 24C2.54741 23.999 2.00071 23.4521 2 22.7773V17.2227Z" fill="var(--primary)" />
                                                <path d="M20 0C16.6863 0 14 2.68629 14 6C14 9.31371 16.6863 12 20 12C23.3137 12 26 9.31371 26 6C25.9964 2.6878 23.3122 0.00363779 20 0ZM20 10C17.7909 10 16 8.20914 16 6C16 3.79086 17.7909 2 20 2C22.2091 2 24 3.79086 24 6C23.9977 8.20818 22.2082 9.99769 20 10Z" fill="var(--primary)" />
                                                <path d="M17.2222 14C15.4432 14.0019 14.0017 15.4437 14 17.2227L14 22.7773C14.0017 24.5563 15.4432 25.9981 17.2222 26L22.7778 26C24.5568 25.9981 25.9984 24.5563 26 22.7773L26 17.2227C25.9983 15.4437 24.5568 14.0019 22.7778 14L17.2222 14ZM24 17.2227V22.7773C23.9993 23.4521 23.4526 23.999 22.7778 24L17.2222 24C16.5474 23.999 16.0007 23.4521 16 22.7773V17.2227C16.0007 16.5479 16.5474 16.001 17.2222 16H22.7778C23.4526 16.001 23.9993 16.5479 24 17.2227Z" fill="var(--primary)" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="card">
                                    <div className="card-body d-flex justify-content-between">
                                        <div className="card-menu">
                                            <span>{t("Total Payments")}</span>
                                            <h2 className="mb-0">{adminNumbers?.totalPayments}</h2>
                                        </div>
                                        <div className="icon-box icon-box-lg bg-primary-light">
                                            <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M8.45 29.3C10.0102 29.3093 11.5568 29.0088 13 28.416C14.4417 29.0138 15.9893 29.3145 17.55 29.3C22.2885 29.3 26 26.7715 26 23.5422V18.1577C26 14.9284 22.2885 12.4 17.55 12.4C17.3303 12.4 17.1145 12.4104 16.9 12.4221V6.33285C16.9 3.16995 13.1885 0.699951 8.45 0.699951C3.7115 0.699951 0 3.16995 0 6.33285V23.6671C0 26.83 3.7115 29.3 8.45 29.3ZM23.4 23.5422C23.4 25.0359 20.9976 26.7 17.55 26.7C14.1024 26.7 11.7 25.0359 11.7 23.5422V22.3398C13.4605 23.4105 15.4899 23.9566 17.55 23.9141C19.6101 23.9566 21.6395 23.4105 23.4 22.3398V23.5422ZM17.55 15C20.9976 15 23.4 16.664 23.4 18.1577C23.4 19.6514 20.9976 21.3141 17.55 21.3141C14.1024 21.3141 11.7 19.6501 11.7 18.1577C11.7 16.6653 14.1024 15 17.55 15ZM8.45 3.29995C11.8976 3.29995 14.3 4.89895 14.3 6.33285C14.3 7.76675 11.8976 9.36705 8.45 9.36705C5.0024 9.36705 2.6 7.76805 2.6 6.33285C2.6 4.89765 5.0024 3.29995 8.45 3.29995ZM2.6 10.4266C4.36783 11.4764 6.39439 12.0101 8.45 11.967C10.5056 12.0101 12.5322 11.4764 14.3 10.4266L14.3 12.8289C12.8832 13.186 11.5839 13.9061 10.53 14.918C9.84648 15.066 9.14934 15.1418 8.45 15.1443C5.0024 15.1443 2.6 13.5453 2.6 12.1114V10.4266ZM2.6 16.2051C4.3682 17.2539 6.39459 17.787 8.45 17.7443C8.6814 17.7443 8.905 17.7157 9.1325 17.704C9.11313 17.8545 9.10228 18.0059 9.1 18.1576V20.8682C8.8816 20.8812 8.671 20.9228 8.45 20.9228C5.0024 20.9228 2.6 19.3238 2.6 17.8886V16.2051ZM2.6 21.9823C4.36783 23.0321 6.39439 23.5658 8.45 23.5228C8.6684 23.5228 8.8829 23.5058 9.1 23.4955V23.5422C9.1186 24.6489 9.54387 25.71 10.2947 26.5232C9.68645 26.638 9.06899 26.6972 8.45 26.7C5.0024 26.7 2.6 25.101 2.6 23.6671V21.9823Z" fill="var(--primary)" />
                                            </svg>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="card">
                                    <div className="card-body d-flex justify-content-between">
                                        <div className="card-menu">
                                            <span>{t("Tenants Near To Expire")}</span>
                                            <h2 className="mb-0">{adminNumbers?.tenantsNearToExpire}</h2>
                                        </div>
                                        <div className="icon-box icon-box-lg bg-primary-light">
                                            <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M3.5 24.5001H4.5V27.5001C4.5 27.8384 4.67123 28.1539 4.95504 28.3384C5.23903 28.5229 5.5969 28.5512 5.9062 28.4137L14.7118 24.5001H21.5C23.1561 24.4983 24.4982 23.1562 24.5 21.5001L24.5 13.5001C24.5 12.9478 24.0523 12.5001 23.5 12.5001C22.9477 12.5001 22.5 12.9478 22.5 13.5001V21.5001C22.4995 22.0522 22.0521 22.4996 21.5 22.5001L14.5 22.5001L14.4916 22.5018C14.4266 22.5083 14.3625 22.5212 14.3 22.5401C14.2324 22.5482 14.1658 22.5631 14.1012 22.5845L14.0934 22.5862L6.5 25.9615V23.5001C6.5 22.9478 6.05228 22.5001 5.5 22.5001H3.5C2.94792 22.4996 2.50049 22.0522 2.5 21.5001V7.50012C2.5005 6.94804 2.94792 6.50062 3.5 6.50012L15.42 6.50012C15.9723 6.50012 16.42 6.05241 16.42 5.50012C16.42 4.94784 15.9723 4.50012 15.42 4.50012H3.5C1.8439 4.50194 0.501819 5.84402 0.5 7.50012L0.5 21.5001C0.50182 23.1562 1.8439 24.4983 3.5 24.5001Z" fill="var(--primary)" />
                                                <path d="M23.5 10.5001C26.2614 10.5001 28.5 8.26155 28.5 5.50012C28.5 2.7387 26.2614 0.500122 23.5 0.500122C20.7386 0.500122 18.5 2.7387 18.5 5.50012C18.5033 8.2602 20.7399 10.4969 23.5 10.5001ZM23.5 2.50012C25.1569 2.50012 26.5 3.84327 26.5 5.50012C26.5 7.15698 25.1569 8.50012 23.5 8.50012C21.8431 8.50012 20.5 7.15698 20.5 5.50012C20.5018 3.84402 21.8439 2.50194 23.5 2.50012Z" fill="var(--primary)" />
                                                <path d="M5.5 10.5001H10.5C11.0523 10.5001 11.5 10.0524 11.5 9.50012C11.5 8.94784 11.0523 8.50012 10.5 8.50012H5.5C4.94772 8.50012 4.5 8.94784 4.5 9.50012C4.5 10.0524 4.94772 10.5001 5.5 10.5001Z" fill="var(--primary)" />
                                                <path d="M5.5 14.5001H14.5C15.0523 14.5001 15.5 14.0524 15.5 13.5001C15.5 12.9478 15.0523 12.5001 14.5 12.5001H5.5C4.94772 12.5001 4.5 12.9478 4.5 13.5001C4.5 14.0524 4.94772 14.5001 5.5 14.5001Z" fill="var(--primary)" />
                                            </svg>


                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-3 col-sm-6">
                                <div className="card">
                                    <div className="card-body d-flex justify-content-between">
                                        <div className="card-menu">
                                            <span>{t("Total Customers")}</span>
                                            <h2 className="mb-0">{adminNumbers?.totalCustomers}</h2>
                                        </div>
                                        <div className="icon-box icon-box-lg bg-primary-light">
                                            <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M11.8413 15C14.0504 15 15.8413 13.2091 15.8413 11C15.8413 8.79086 14.0504 7 11.8413 7C9.63217 7 7.84131 8.79086 7.84131 11C7.84346 13.2082 9.63306 14.9979 11.8413 15ZM11.8413 9C12.9459 9 13.8413 9.89543 13.8413 11C13.8413 12.1046 12.9459 13 11.8413 13C10.7367 13 9.84131 12.1046 9.84131 11C9.8428 9.89605 10.7374 9.00149 11.8413 9Z" fill="#1921FA" />
                                                <path d="M27.4653 17.57C28.1349 16.6731 28.3336 15.5094 27.9995 14.4411L27.3647 12.3757C26.7526 10.3642 24.8938 8.99215 22.7913 9L18.3042 9C17.7519 9 17.3042 9.44772 17.3042 10C17.3042 10.5523 17.7519 11 18.3042 11H22.7913C24.0147 10.9958 25.0962 11.7943 25.4524 12.9648L26.0872 15.0293C26.2292 15.4911 26.1437 15.9929 25.8568 16.3816C25.5698 16.7703 25.1154 16.9998 24.6323 17L15.4634 17C15.4351 17 15.4114 17.0137 15.3834 17.0161C15.3162 17.0135 15.2513 17 15.1831 17H8.91306C6.63117 16.9917 4.61413 18.4815 3.95116 20.665L3.20456 23.09C2.85091 24.2408 3.0643 25.4912 3.77961 26.4597C4.49492 27.4281 5.6273 27.9997 6.83126 28H17.2642C18.4675 28 19.6001 27.4287 20.3157 26.4604C21.0315 25.4914 21.2449 24.2409 20.8913 23.09L20.1452 20.6652C19.9562 20.0614 19.6586 19.4971 19.2671 19H24.6323C25.7513 19.0052 26.8048 18.4733 27.4653 17.57ZM18.7075 25.2712C18.371 25.7315 17.8343 26.0025 17.2642 26L6.83126 26C6.26201 26 5.72636 25.7297 5.38805 25.2717C5.04968 24.8134 4.94894 24.222 5.11646 23.6777L5.86256 21.2529C6.2696 19.9104 7.50972 18.9944 8.91256 19H15.1826C16.5854 18.9944 17.8255 19.9104 18.2326 21.2529L18.9787 23.6777C19.1493 24.2217 19.0484 24.8143 18.7075 25.2712Z" fill="#1921FA" />
                                                <path d="M20.3413 7C22.2743 7 23.8413 5.433 23.8413 3.5C23.8413 1.567 22.2743 0 20.3413 0C18.4083 0 16.8413 1.567 16.8413 3.5C16.8436 5.43204 18.4093 6.99768 20.3413 7ZM20.3413 2C21.1697 2 21.8413 2.67157 21.8413 3.5C21.8413 4.32843 21.1697 5 20.3413 5C19.5129 5 18.8413 4.32843 18.8413 3.5C18.8422 2.67196 19.5133 2.00094 20.3413 2Z" fill="#1921FA" />
                                                <path d="M0.841309 4C0.841309 4.55228 1.28902 5 1.84131 5H3.84131V7C3.84131 7.55229 4.28902 8 4.84131 8C5.39359 8 5.84131 7.55228 5.84131 7V5H7.84131C8.39359 5 8.84131 4.55228 8.84131 4C8.84131 3.44772 8.39359 3 7.84131 3H5.84131V1C5.84131 0.447715 5.39359 0 4.84131 0C4.28902 0 3.84131 0.447715 3.84131 1V3H1.84131C1.28902 3 0.841308 3.44772 0.841309 4Z" fill="#1921FA" />
                                            </svg>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </> :
                            <div style={{ height: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                                <InfinitySpin
                                    width='200'
                                    color="rgb(69, 39, 160)"
                                />
                            </div>}
                    </>
                    <>
                        <div className="col-xl-6 custome-width">
                            <div className="card">
                                <div className="card-body ">
                                    <div className="card-header border-0 pb-0">
                                        <h3 className="h-title">{t("reviews")}</h3>

                                    </div>
                                    {
                                        isLoadingReviews == false && reviewsChart ? <>

                                            <PieChart data={reviewsChart} />
                                        </> :
                                            <div style={{ height: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>    <InfinitySpin
                                                width='200'
                                                color="rgb(69, 39, 160)"
                                            />
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 custome-width">
                            <div className="card">
                                <div className="card-body " style={{ position: 'relative' }}>
                                    <div className="card-header border-0 pb-0">
                                        <h3 className="h-title">{t("reservations")}</h3>

                                    </div>
                                    {isLoadingReservationsLoading == false && reservationsChart ? <><BarChart
                                        axisBottomLabel={t('months')}
                                        axisLeftLabel={t('reservations')}

                                        indexedBy={"months"}
                                        keys={[
                                            'reservations',

                                        ]}
                                        data={reservationsChart}
                                    />

                                        {/*  <div style={{ position: 'absolute', bottom: 50, }}>
                                            {Object.keys(reservationsChart?.[0])?.filter((item) => item != "months")?.map((item: any) => <div style={{ display: 'flex', marginInlineEnd: 10, alignItems: 'center' }}>
                                                <div style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: "#4527a0" }}>
                                                </div>
                                                <span style={{ marginInlineStart: 2 }}>
                                                    {t(item)}</span>
                                            </div>)}
                                        </div> */}

                                    </> :
                                        <div style={{ height: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>    <InfinitySpin
                                            width='200'
                                            color="rgb(69, 39, 160)"
                                        />
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </>
                </> : (data?.data?.roles?.includes("TenantManager")) ?
                    <>
                        <>
                            {isLoadingTenantNumbers == false && adminTenantNumbers ? <>
                                <div className="col-xl-3 col-sm-6">
                                    <div className="card">
                                        <div className="card-body d-flex justify-content-between">
                                            <div className="card-menu">
                                                <span>{t("Total Bookings")}</span>
                                                <h2 className="mb-0">{adminTenantNumbers?.totalBookings}</h2>
                                            </div>
                                            <div className="icon-box icon-box-lg bg-primary-light">
                                                <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M0 8.7773C0.00165391 10.5563 1.44323 11.9981 3.2222 12H8.7778C10.5568 11.9981 11.9983 10.5563 12 8.7773V3.2227C11.9983 1.44373 10.5568 0.00192952 8.7778 0H3.2222C1.44323 0.00192952 0.00165319 1.44373 0 3.2227V8.7773ZM2 3.2227C2.00072 2.54791 2.54741 2.00099 3.2222 2L8.7778 2C9.45259 2.00099 9.99928 2.54791 10 3.2227V8.7773C9.99929 9.45209 9.45259 9.99901 8.7778 10L3.2222 10C2.54741 9.99901 2.00072 9.45209 2 8.7773V3.2227Z" fill="var(--primary)" />
                                                    <path d="M0 22.7773C0.00165272 24.5563 1.44323 25.9981 3.2222 26L8.7778 26C10.5568 25.9981 11.9983 24.5563 12 22.7773V17.2227C11.9983 15.4437 10.5568 14.0019 8.7778 14L3.2222 14C1.44323 14.0019 0.00165391 15.4437 0 17.2227V22.7773ZM2 17.2227C2.00072 16.5479 2.54741 16.001 3.2222 16H8.7778C9.45259 16.001 9.99928 16.5479 10 17.2227V22.7773C9.99929 23.4521 9.45259 23.999 8.7778 24L3.2222 24C2.54741 23.999 2.00071 23.4521 2 22.7773V17.2227Z" fill="var(--primary)" />
                                                    <path d="M20 0C16.6863 0 14 2.68629 14 6C14 9.31371 16.6863 12 20 12C23.3137 12 26 9.31371 26 6C25.9964 2.6878 23.3122 0.00363779 20 0ZM20 10C17.7909 10 16 8.20914 16 6C16 3.79086 17.7909 2 20 2C22.2091 2 24 3.79086 24 6C23.9977 8.20818 22.2082 9.99769 20 10Z" fill="var(--primary)" />
                                                    <path d="M17.2222 14C15.4432 14.0019 14.0017 15.4437 14 17.2227L14 22.7773C14.0017 24.5563 15.4432 25.9981 17.2222 26L22.7778 26C24.5568 25.9981 25.9984 24.5563 26 22.7773L26 17.2227C25.9983 15.4437 24.5568 14.0019 22.7778 14L17.2222 14ZM24 17.2227V22.7773C23.9993 23.4521 23.4526 23.999 22.7778 24L17.2222 24C16.5474 23.999 16.0007 23.4521 16 22.7773V17.2227C16.0007 16.5479 16.5474 16.001 17.2222 16H22.7778C23.4526 16.001 23.9993 16.5479 24 17.2227Z" fill="var(--primary)" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-sm-6">
                                    <div className="card">
                                        <div className="card-body d-flex justify-content-between">
                                            <div className="card-menu">
                                                <span>{t("Total Payments")}</span>
                                                <h2 className="mb-0">{adminTenantNumbers?.totalPayments}</h2>
                                            </div>
                                            <div className="icon-box icon-box-lg bg-primary-light">
                                                <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M8.45 29.3C10.0102 29.3093 11.5568 29.0088 13 28.416C14.4417 29.0138 15.9893 29.3145 17.55 29.3C22.2885 29.3 26 26.7715 26 23.5422V18.1577C26 14.9284 22.2885 12.4 17.55 12.4C17.3303 12.4 17.1145 12.4104 16.9 12.4221V6.33285C16.9 3.16995 13.1885 0.699951 8.45 0.699951C3.7115 0.699951 0 3.16995 0 6.33285V23.6671C0 26.83 3.7115 29.3 8.45 29.3ZM23.4 23.5422C23.4 25.0359 20.9976 26.7 17.55 26.7C14.1024 26.7 11.7 25.0359 11.7 23.5422V22.3398C13.4605 23.4105 15.4899 23.9566 17.55 23.9141C19.6101 23.9566 21.6395 23.4105 23.4 22.3398V23.5422ZM17.55 15C20.9976 15 23.4 16.664 23.4 18.1577C23.4 19.6514 20.9976 21.3141 17.55 21.3141C14.1024 21.3141 11.7 19.6501 11.7 18.1577C11.7 16.6653 14.1024 15 17.55 15ZM8.45 3.29995C11.8976 3.29995 14.3 4.89895 14.3 6.33285C14.3 7.76675 11.8976 9.36705 8.45 9.36705C5.0024 9.36705 2.6 7.76805 2.6 6.33285C2.6 4.89765 5.0024 3.29995 8.45 3.29995ZM2.6 10.4266C4.36783 11.4764 6.39439 12.0101 8.45 11.967C10.5056 12.0101 12.5322 11.4764 14.3 10.4266L14.3 12.8289C12.8832 13.186 11.5839 13.9061 10.53 14.918C9.84648 15.066 9.14934 15.1418 8.45 15.1443C5.0024 15.1443 2.6 13.5453 2.6 12.1114V10.4266ZM2.6 16.2051C4.3682 17.2539 6.39459 17.787 8.45 17.7443C8.6814 17.7443 8.905 17.7157 9.1325 17.704C9.11313 17.8545 9.10228 18.0059 9.1 18.1576V20.8682C8.8816 20.8812 8.671 20.9228 8.45 20.9228C5.0024 20.9228 2.6 19.3238 2.6 17.8886V16.2051ZM2.6 21.9823C4.36783 23.0321 6.39439 23.5658 8.45 23.5228C8.6684 23.5228 8.8829 23.5058 9.1 23.4955V23.5422C9.1186 24.6489 9.54387 25.71 10.2947 26.5232C9.68645 26.638 9.06899 26.6972 8.45 26.7C5.0024 26.7 2.6 25.101 2.6 23.6671V21.9823Z" fill="var(--primary)" />
                                                </svg>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-sm-6">
                                    <div className="card">
                                        <div className="card-body d-flex justify-content-between">
                                            <div className="card-menu">
                                                <span>{t("Total Canceled Bookings")}</span>
                                                <h2 className="mb-0">{adminTenantNumbers?.totalCanceledBookings}</h2>
                                            </div>
                                            <div className="icon-box icon-box-lg bg-primary-light">
                                                <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M3.5 24.5001H4.5V27.5001C4.5 27.8384 4.67123 28.1539 4.95504 28.3384C5.23903 28.5229 5.5969 28.5512 5.9062 28.4137L14.7118 24.5001H21.5C23.1561 24.4983 24.4982 23.1562 24.5 21.5001L24.5 13.5001C24.5 12.9478 24.0523 12.5001 23.5 12.5001C22.9477 12.5001 22.5 12.9478 22.5 13.5001V21.5001C22.4995 22.0522 22.0521 22.4996 21.5 22.5001L14.5 22.5001L14.4916 22.5018C14.4266 22.5083 14.3625 22.5212 14.3 22.5401C14.2324 22.5482 14.1658 22.5631 14.1012 22.5845L14.0934 22.5862L6.5 25.9615V23.5001C6.5 22.9478 6.05228 22.5001 5.5 22.5001H3.5C2.94792 22.4996 2.50049 22.0522 2.5 21.5001V7.50012C2.5005 6.94804 2.94792 6.50062 3.5 6.50012L15.42 6.50012C15.9723 6.50012 16.42 6.05241 16.42 5.50012C16.42 4.94784 15.9723 4.50012 15.42 4.50012H3.5C1.8439 4.50194 0.501819 5.84402 0.5 7.50012L0.5 21.5001C0.50182 23.1562 1.8439 24.4983 3.5 24.5001Z" fill="var(--primary)" />
                                                    <path d="M23.5 10.5001C26.2614 10.5001 28.5 8.26155 28.5 5.50012C28.5 2.7387 26.2614 0.500122 23.5 0.500122C20.7386 0.500122 18.5 2.7387 18.5 5.50012C18.5033 8.2602 20.7399 10.4969 23.5 10.5001ZM23.5 2.50012C25.1569 2.50012 26.5 3.84327 26.5 5.50012C26.5 7.15698 25.1569 8.50012 23.5 8.50012C21.8431 8.50012 20.5 7.15698 20.5 5.50012C20.5018 3.84402 21.8439 2.50194 23.5 2.50012Z" fill="var(--primary)" />
                                                    <path d="M5.5 10.5001H10.5C11.0523 10.5001 11.5 10.0524 11.5 9.50012C11.5 8.94784 11.0523 8.50012 10.5 8.50012H5.5C4.94772 8.50012 4.5 8.94784 4.5 9.50012C4.5 10.0524 4.94772 10.5001 5.5 10.5001Z" fill="var(--primary)" />
                                                    <path d="M5.5 14.5001H14.5C15.0523 14.5001 15.5 14.0524 15.5 13.5001C15.5 12.9478 15.0523 12.5001 14.5 12.5001H5.5C4.94772 12.5001 4.5 12.9478 4.5 13.5001C4.5 14.0524 4.94772 14.5001 5.5 14.5001Z" fill="var(--primary)" />
                                                </svg>


                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-sm-6">
                                    <div className="card">
                                        <div className="card-body d-flex justify-content-between">
                                            <div className="card-menu">
                                                <span>{t("Total Reviews")}</span>
                                                <h2 className="mb-0">{adminTenantNumbers?.totalReviews}</h2>
                                            </div>
                                            <div className="icon-box icon-box-lg bg-primary-light">
                                                <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.8413 15C14.0504 15 15.8413 13.2091 15.8413 11C15.8413 8.79086 14.0504 7 11.8413 7C9.63217 7 7.84131 8.79086 7.84131 11C7.84346 13.2082 9.63306 14.9979 11.8413 15ZM11.8413 9C12.9459 9 13.8413 9.89543 13.8413 11C13.8413 12.1046 12.9459 13 11.8413 13C10.7367 13 9.84131 12.1046 9.84131 11C9.8428 9.89605 10.7374 9.00149 11.8413 9Z" fill="#1921FA" />
                                                    <path d="M27.4653 17.57C28.1349 16.6731 28.3336 15.5094 27.9995 14.4411L27.3647 12.3757C26.7526 10.3642 24.8938 8.99215 22.7913 9L18.3042 9C17.7519 9 17.3042 9.44772 17.3042 10C17.3042 10.5523 17.7519 11 18.3042 11H22.7913C24.0147 10.9958 25.0962 11.7943 25.4524 12.9648L26.0872 15.0293C26.2292 15.4911 26.1437 15.9929 25.8568 16.3816C25.5698 16.7703 25.1154 16.9998 24.6323 17L15.4634 17C15.4351 17 15.4114 17.0137 15.3834 17.0161C15.3162 17.0135 15.2513 17 15.1831 17H8.91306C6.63117 16.9917 4.61413 18.4815 3.95116 20.665L3.20456 23.09C2.85091 24.2408 3.0643 25.4912 3.77961 26.4597C4.49492 27.4281 5.6273 27.9997 6.83126 28H17.2642C18.4675 28 19.6001 27.4287 20.3157 26.4604C21.0315 25.4914 21.2449 24.2409 20.8913 23.09L20.1452 20.6652C19.9562 20.0614 19.6586 19.4971 19.2671 19H24.6323C25.7513 19.0052 26.8048 18.4733 27.4653 17.57ZM18.7075 25.2712C18.371 25.7315 17.8343 26.0025 17.2642 26L6.83126 26C6.26201 26 5.72636 25.7297 5.38805 25.2717C5.04968 24.8134 4.94894 24.222 5.11646 23.6777L5.86256 21.2529C6.2696 19.9104 7.50972 18.9944 8.91256 19H15.1826C16.5854 18.9944 17.8255 19.9104 18.2326 21.2529L18.9787 23.6777C19.1493 24.2217 19.0484 24.8143 18.7075 25.2712Z" fill="#1921FA" />
                                                    <path d="M20.3413 7C22.2743 7 23.8413 5.433 23.8413 3.5C23.8413 1.567 22.2743 0 20.3413 0C18.4083 0 16.8413 1.567 16.8413 3.5C16.8436 5.43204 18.4093 6.99768 20.3413 7ZM20.3413 2C21.1697 2 21.8413 2.67157 21.8413 3.5C21.8413 4.32843 21.1697 5 20.3413 5C19.5129 5 18.8413 4.32843 18.8413 3.5C18.8422 2.67196 19.5133 2.00094 20.3413 2Z" fill="#1921FA" />
                                                    <path d="M0.841309 4C0.841309 4.55228 1.28902 5 1.84131 5H3.84131V7C3.84131 7.55229 4.28902 8 4.84131 8C5.39359 8 5.84131 7.55228 5.84131 7V5H7.84131C8.39359 5 8.84131 4.55228 8.84131 4C8.84131 3.44772 8.39359 3 7.84131 3H5.84131V1C5.84131 0.447715 5.39359 0 4.84131 0C4.28902 0 3.84131 0.447715 3.84131 1V3H1.84131C1.28902 3 0.841308 3.44772 0.841309 4Z" fill="#1921FA" />
                                                </svg>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </> :
                                <div style={{ height: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                                    <InfinitySpin
                                        width='200'
                                        color="rgb(69, 39, 160)"
                                    />
                                </div>}
                        </>
                        <>
                            <div className="col-xl-6 custome-width">
                                <div className="card">
                                    <div className="card-body ">
                                        <div className="card-header border-0 pb-0">
                                            <h3 className="h-title">{t("reviews")}</h3>

                                        </div>
                                        {
                                            isLoadingTenantReviews == false && reviewsTenantChart ? <>
                                                <PieChart data={reviewsTenantChart} />
                                            </> :
                                                <div style={{ height: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>    <InfinitySpin
                                                    width='200'
                                                    color="rgb(69, 39, 160)"
                                                />
                                                </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 custome-width">
                                <div className="card">
                                    <div className="card-body " style={{ position: 'relative' }}>
                                        <div className="card-header border-0 pb-0">
                                            <h3 className="h-title">{t("reservations")}</h3>

                                        </div>
                                        {isLoadingReservationsTenantLoading == false && reservationsTenantChart ? <><BarChart
                                            axisBottomLabel={t('months')}
                                            axisLeftLabel={t('reservations')}

                                            indexedBy={"months"}
                                            keys={[
                                                'reservations',

                                            ]}
                                            data={reservationsTenantChart}
                                        />

                                            {/* <div style={{ position: 'absolute', bottom: 50, }}>
                                                {Object.keys(reservationsTenantChart?.[0])?.filter((item) => item != "months")?.map((item: any) => <div style={{ display: 'flex', marginInlineEnd: 10, alignItems: 'center' }}>
                                                    <div style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: "#4527a0" }}>
                                                    </div>
                                                    <span style={{ marginInlineStart: 2 }}>
                                                        {t(item)}</span>
                                                </div>)}
                                            </div> */}

                                        </> :
                                            <div style={{ height: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>    <InfinitySpin
                                                width='200'
                                                color="rgb(69, 39, 160)"
                                            />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </>
                    </> : (data?.data?.roles?.includes("BranchManager")) ?
                        <>
                            <>
                                {isLoadingBranchNumbers == false && adminBranchNumbers ? <>
                                    <div className="col-xl-3 col-sm-6">
                                        <div className="card">
                                            <div className="card-body d-flex justify-content-between">
                                                <div className="card-menu">
                                                    <span>{t("Total Bookings")}</span>
                                                    <h2 className="mb-0">{adminBranchNumbers?.totalBookings}</h2>
                                                </div>
                                                <div className="icon-box icon-box-lg bg-primary-light">
                                                    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M0 8.7773C0.00165391 10.5563 1.44323 11.9981 3.2222 12H8.7778C10.5568 11.9981 11.9983 10.5563 12 8.7773V3.2227C11.9983 1.44373 10.5568 0.00192952 8.7778 0H3.2222C1.44323 0.00192952 0.00165319 1.44373 0 3.2227V8.7773ZM2 3.2227C2.00072 2.54791 2.54741 2.00099 3.2222 2L8.7778 2C9.45259 2.00099 9.99928 2.54791 10 3.2227V8.7773C9.99929 9.45209 9.45259 9.99901 8.7778 10L3.2222 10C2.54741 9.99901 2.00072 9.45209 2 8.7773V3.2227Z" fill="var(--primary)" />
                                                        <path d="M0 22.7773C0.00165272 24.5563 1.44323 25.9981 3.2222 26L8.7778 26C10.5568 25.9981 11.9983 24.5563 12 22.7773V17.2227C11.9983 15.4437 10.5568 14.0019 8.7778 14L3.2222 14C1.44323 14.0019 0.00165391 15.4437 0 17.2227V22.7773ZM2 17.2227C2.00072 16.5479 2.54741 16.001 3.2222 16H8.7778C9.45259 16.001 9.99928 16.5479 10 17.2227V22.7773C9.99929 23.4521 9.45259 23.999 8.7778 24L3.2222 24C2.54741 23.999 2.00071 23.4521 2 22.7773V17.2227Z" fill="var(--primary)" />
                                                        <path d="M20 0C16.6863 0 14 2.68629 14 6C14 9.31371 16.6863 12 20 12C23.3137 12 26 9.31371 26 6C25.9964 2.6878 23.3122 0.00363779 20 0ZM20 10C17.7909 10 16 8.20914 16 6C16 3.79086 17.7909 2 20 2C22.2091 2 24 3.79086 24 6C23.9977 8.20818 22.2082 9.99769 20 10Z" fill="var(--primary)" />
                                                        <path d="M17.2222 14C15.4432 14.0019 14.0017 15.4437 14 17.2227L14 22.7773C14.0017 24.5563 15.4432 25.9981 17.2222 26L22.7778 26C24.5568 25.9981 25.9984 24.5563 26 22.7773L26 17.2227C25.9983 15.4437 24.5568 14.0019 22.7778 14L17.2222 14ZM24 17.2227V22.7773C23.9993 23.4521 23.4526 23.999 22.7778 24L17.2222 24C16.5474 23.999 16.0007 23.4521 16 22.7773V17.2227C16.0007 16.5479 16.5474 16.001 17.2222 16H22.7778C23.4526 16.001 23.9993 16.5479 24 17.2227Z" fill="var(--primary)" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-sm-6">
                                        <div className="card">
                                            <div className="card-body d-flex justify-content-between">
                                                <div className="card-menu">
                                                    <span>{t("Total Payments")}</span>
                                                    <h2 className="mb-0">{adminBranchNumbers?.totalPayments}</h2>
                                                </div>
                                                <div className="icon-box icon-box-lg bg-primary-light">
                                                    <svg width="26" height="30" viewBox="0 0 26 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M8.45 29.3C10.0102 29.3093 11.5568 29.0088 13 28.416C14.4417 29.0138 15.9893 29.3145 17.55 29.3C22.2885 29.3 26 26.7715 26 23.5422V18.1577C26 14.9284 22.2885 12.4 17.55 12.4C17.3303 12.4 17.1145 12.4104 16.9 12.4221V6.33285C16.9 3.16995 13.1885 0.699951 8.45 0.699951C3.7115 0.699951 0 3.16995 0 6.33285V23.6671C0 26.83 3.7115 29.3 8.45 29.3ZM23.4 23.5422C23.4 25.0359 20.9976 26.7 17.55 26.7C14.1024 26.7 11.7 25.0359 11.7 23.5422V22.3398C13.4605 23.4105 15.4899 23.9566 17.55 23.9141C19.6101 23.9566 21.6395 23.4105 23.4 22.3398V23.5422ZM17.55 15C20.9976 15 23.4 16.664 23.4 18.1577C23.4 19.6514 20.9976 21.3141 17.55 21.3141C14.1024 21.3141 11.7 19.6501 11.7 18.1577C11.7 16.6653 14.1024 15 17.55 15ZM8.45 3.29995C11.8976 3.29995 14.3 4.89895 14.3 6.33285C14.3 7.76675 11.8976 9.36705 8.45 9.36705C5.0024 9.36705 2.6 7.76805 2.6 6.33285C2.6 4.89765 5.0024 3.29995 8.45 3.29995ZM2.6 10.4266C4.36783 11.4764 6.39439 12.0101 8.45 11.967C10.5056 12.0101 12.5322 11.4764 14.3 10.4266L14.3 12.8289C12.8832 13.186 11.5839 13.9061 10.53 14.918C9.84648 15.066 9.14934 15.1418 8.45 15.1443C5.0024 15.1443 2.6 13.5453 2.6 12.1114V10.4266ZM2.6 16.2051C4.3682 17.2539 6.39459 17.787 8.45 17.7443C8.6814 17.7443 8.905 17.7157 9.1325 17.704C9.11313 17.8545 9.10228 18.0059 9.1 18.1576V20.8682C8.8816 20.8812 8.671 20.9228 8.45 20.9228C5.0024 20.9228 2.6 19.3238 2.6 17.8886V16.2051ZM2.6 21.9823C4.36783 23.0321 6.39439 23.5658 8.45 23.5228C8.6684 23.5228 8.8829 23.5058 9.1 23.4955V23.5422C9.1186 24.6489 9.54387 25.71 10.2947 26.5232C9.68645 26.638 9.06899 26.6972 8.45 26.7C5.0024 26.7 2.6 25.101 2.6 23.6671V21.9823Z" fill="var(--primary)" />
                                                    </svg>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-sm-6">
                                        <div className="card">
                                            <div className="card-body d-flex justify-content-between">
                                                <div className="card-menu">
                                                    <span>{t("Total Canceled Bookings")}</span>
                                                    <h2 className="mb-0">{adminBranchNumbers?.totalCanceledBookings}</h2>
                                                </div>
                                                <div className="icon-box icon-box-lg bg-primary-light">
                                                    <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M3.5 24.5001H4.5V27.5001C4.5 27.8384 4.67123 28.1539 4.95504 28.3384C5.23903 28.5229 5.5969 28.5512 5.9062 28.4137L14.7118 24.5001H21.5C23.1561 24.4983 24.4982 23.1562 24.5 21.5001L24.5 13.5001C24.5 12.9478 24.0523 12.5001 23.5 12.5001C22.9477 12.5001 22.5 12.9478 22.5 13.5001V21.5001C22.4995 22.0522 22.0521 22.4996 21.5 22.5001L14.5 22.5001L14.4916 22.5018C14.4266 22.5083 14.3625 22.5212 14.3 22.5401C14.2324 22.5482 14.1658 22.5631 14.1012 22.5845L14.0934 22.5862L6.5 25.9615V23.5001C6.5 22.9478 6.05228 22.5001 5.5 22.5001H3.5C2.94792 22.4996 2.50049 22.0522 2.5 21.5001V7.50012C2.5005 6.94804 2.94792 6.50062 3.5 6.50012L15.42 6.50012C15.9723 6.50012 16.42 6.05241 16.42 5.50012C16.42 4.94784 15.9723 4.50012 15.42 4.50012H3.5C1.8439 4.50194 0.501819 5.84402 0.5 7.50012L0.5 21.5001C0.50182 23.1562 1.8439 24.4983 3.5 24.5001Z" fill="var(--primary)" />
                                                        <path d="M23.5 10.5001C26.2614 10.5001 28.5 8.26155 28.5 5.50012C28.5 2.7387 26.2614 0.500122 23.5 0.500122C20.7386 0.500122 18.5 2.7387 18.5 5.50012C18.5033 8.2602 20.7399 10.4969 23.5 10.5001ZM23.5 2.50012C25.1569 2.50012 26.5 3.84327 26.5 5.50012C26.5 7.15698 25.1569 8.50012 23.5 8.50012C21.8431 8.50012 20.5 7.15698 20.5 5.50012C20.5018 3.84402 21.8439 2.50194 23.5 2.50012Z" fill="var(--primary)" />
                                                        <path d="M5.5 10.5001H10.5C11.0523 10.5001 11.5 10.0524 11.5 9.50012C11.5 8.94784 11.0523 8.50012 10.5 8.50012H5.5C4.94772 8.50012 4.5 8.94784 4.5 9.50012C4.5 10.0524 4.94772 10.5001 5.5 10.5001Z" fill="var(--primary)" />
                                                        <path d="M5.5 14.5001H14.5C15.0523 14.5001 15.5 14.0524 15.5 13.5001C15.5 12.9478 15.0523 12.5001 14.5 12.5001H5.5C4.94772 12.5001 4.5 12.9478 4.5 13.5001C4.5 14.0524 4.94772 14.5001 5.5 14.5001Z" fill="var(--primary)" />
                                                    </svg>


                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-xl-3 col-sm-6">
                                        <div className="card">
                                            <div className="card-body d-flex justify-content-between">
                                                <div className="card-menu">
                                                    <span>{t("Total Reviews")}</span>
                                                    <h2 className="mb-0">{adminBranchNumbers?.totalReviews}</h2>
                                                </div>
                                                <div className="icon-box icon-box-lg bg-primary-light">
                                                    <svg width="29" height="28" viewBox="0 0 29 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path d="M11.8413 15C14.0504 15 15.8413 13.2091 15.8413 11C15.8413 8.79086 14.0504 7 11.8413 7C9.63217 7 7.84131 8.79086 7.84131 11C7.84346 13.2082 9.63306 14.9979 11.8413 15ZM11.8413 9C12.9459 9 13.8413 9.89543 13.8413 11C13.8413 12.1046 12.9459 13 11.8413 13C10.7367 13 9.84131 12.1046 9.84131 11C9.8428 9.89605 10.7374 9.00149 11.8413 9Z" fill="#1921FA" />
                                                        <path d="M27.4653 17.57C28.1349 16.6731 28.3336 15.5094 27.9995 14.4411L27.3647 12.3757C26.7526 10.3642 24.8938 8.99215 22.7913 9L18.3042 9C17.7519 9 17.3042 9.44772 17.3042 10C17.3042 10.5523 17.7519 11 18.3042 11H22.7913C24.0147 10.9958 25.0962 11.7943 25.4524 12.9648L26.0872 15.0293C26.2292 15.4911 26.1437 15.9929 25.8568 16.3816C25.5698 16.7703 25.1154 16.9998 24.6323 17L15.4634 17C15.4351 17 15.4114 17.0137 15.3834 17.0161C15.3162 17.0135 15.2513 17 15.1831 17H8.91306C6.63117 16.9917 4.61413 18.4815 3.95116 20.665L3.20456 23.09C2.85091 24.2408 3.0643 25.4912 3.77961 26.4597C4.49492 27.4281 5.6273 27.9997 6.83126 28H17.2642C18.4675 28 19.6001 27.4287 20.3157 26.4604C21.0315 25.4914 21.2449 24.2409 20.8913 23.09L20.1452 20.6652C19.9562 20.0614 19.6586 19.4971 19.2671 19H24.6323C25.7513 19.0052 26.8048 18.4733 27.4653 17.57ZM18.7075 25.2712C18.371 25.7315 17.8343 26.0025 17.2642 26L6.83126 26C6.26201 26 5.72636 25.7297 5.38805 25.2717C5.04968 24.8134 4.94894 24.222 5.11646 23.6777L5.86256 21.2529C6.2696 19.9104 7.50972 18.9944 8.91256 19H15.1826C16.5854 18.9944 17.8255 19.9104 18.2326 21.2529L18.9787 23.6777C19.1493 24.2217 19.0484 24.8143 18.7075 25.2712Z" fill="#1921FA" />
                                                        <path d="M20.3413 7C22.2743 7 23.8413 5.433 23.8413 3.5C23.8413 1.567 22.2743 0 20.3413 0C18.4083 0 16.8413 1.567 16.8413 3.5C16.8436 5.43204 18.4093 6.99768 20.3413 7ZM20.3413 2C21.1697 2 21.8413 2.67157 21.8413 3.5C21.8413 4.32843 21.1697 5 20.3413 5C19.5129 5 18.8413 4.32843 18.8413 3.5C18.8422 2.67196 19.5133 2.00094 20.3413 2Z" fill="#1921FA" />
                                                        <path d="M0.841309 4C0.841309 4.55228 1.28902 5 1.84131 5H3.84131V7C3.84131 7.55229 4.28902 8 4.84131 8C5.39359 8 5.84131 7.55228 5.84131 7V5H7.84131C8.39359 5 8.84131 4.55228 8.84131 4C8.84131 3.44772 8.39359 3 7.84131 3H5.84131V1C5.84131 0.447715 5.39359 0 4.84131 0C4.28902 0 3.84131 0.447715 3.84131 1V3H1.84131C1.28902 3 0.841308 3.44772 0.841309 4Z" fill="#1921FA" />
                                                    </svg>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </> :
                                    <div style={{ height: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

                                        <InfinitySpin
                                            width='200'
                                            color="rgb(69, 39, 160)"
                                        />
                                    </div>}
                            </>



                            <>
                                <div className="col-xl-6 custome-width">
                                    <div className="card">
                                        <div className="card-body ">
                                            <div className="card-header border-0 pb-0">
                                                <h3 className="h-title">{t("reviews")}</h3>

                                            </div>
                                            {
                                                isLoadingBranchReviews == false && reviewsBranchChart ? <>
                                                    <PieChart data={reviewsBranchChart} />

                                                </> :
                                                    <div style={{ height: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>    <InfinitySpin
                                                        width='200'
                                                        color="rgb(69, 39, 160)"
                                                    />
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-6 custome-width">
                                    <div className="card">
                                        <div className="card-body " style={{ position: 'relative' }}>
                                            <div className="card-header border-0 pb-0">
                                                <h3 className="h-title">{t("reservations")}</h3>

                                            </div>
                                            {isLoadingReservationsBranchLoading == false && reservationsBranchChart ? <><BarChart
                                                axisBottomLabel={t('months')}
                                                axisLeftLabel={t('reservations')}

                                                indexedBy={"months"}
                                                keys={[
                                                    'reservations',

                                                ]}
                                                data={reservationsBranchChart}
                                            />

                                                {/*   <div style={{ position: 'absolute', bottom: 50, }}>
                                                    {Object.keys(reservationsBranchChart?.[0])?.filter((item) => item != "months")?.map((item: any) => <div style={{ display: 'flex', marginInlineEnd: 10, alignItems: 'center' }}>
                                                        <div style={{ width: 10, height: 10, borderRadius: '50%', backgroundColor: "#4527a0" }}>
                                                        </div>
                                                        <span style={{ marginInlineStart: 2 }}>
                                                            {t(item)}</span>
                                                    </div>)}
                                                </div> */}

                                            </> :
                                                <div style={{ height: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>    <InfinitySpin
                                                    width='200'
                                                    color="rgb(69, 39, 160)"
                                                />
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </>
                        </> : <></>}
                {/*   <div className="col-xl-12">
                    <div className="d-flex justify-content-between mb-4 flex-wrap">
                        <ul className="revnue-tab nav nav-tabs" id="myTab" role="tablist">
                            <li className="nav-item" role="presentation">
                                <button className="nav-link active" id="status-tab" data-bs-toggle="tab" data-bs-target="#status-tab-pane" type="button" role="tab" aria-controls="status-tab-pane" aria-selected="true">All Status</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="delivery-tab" data-bs-toggle="tab" data-bs-target="#delivery-tab-pane" type="button" role="tab" aria-controls="delivery-tab-pane" aria-selected="false">Confirmed</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="Pending-tab" data-bs-toggle="tab" data-bs-target="#Pending-tab-pane" type="button" role="tab" aria-controls="Pending-tab-pane" aria-selected="false">Pending</button>
                            </li>
                            <li className="nav-item" role="presentation">
                                <button className="nav-link" id="canceled-tab" data-bs-toggle="tab" data-bs-target="#canceled-tab-pane" type="button" role="tab" aria-controls="canceled-tab-pane" aria-selected="false">Canceled</button>
                            </li>
                        </ul>
                        <div>
                            <a href="javascript:void(0)" className="btn btn-primary me-1">+ New Booking</a>
                            <select className="default-select h-select ms-1" defaultValue="0" aria-label="Default select example">
                                <option value="0">Week</option>
                                <option value="1">Month</option>
                                <option value="2">Daily</option>

                            </select>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xl-12">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="status-tab-pane" role="tabpanel" aria-labelledby="status-tab">
                                    <div className="card mt-2">
                                        <div className="card-body p-0">
                                            <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting ">
                                                <table id="empoloyees-tbl" className="table">
                                                    <thead>
                                                        <tr>
                                                            <th className="d-flex align-items-center">
                                                                <div className="form-check custom-checkbox ms-0">
                                                                    <input type="checkbox" className="form-check-input checkAllInput" id="checkAll2" required />
                                                                    <label className="form-check-label" htmlFor="checkAll2"></label>
                                                                </div>
                                                            </th>
                                                            <th>Booking ID</th>
                                                            <th>Date</th>
                                                            <th>Customer</th>
                                                            <th>Location</th>
                                                            <th>Amount</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <tr>
                                                            <td>
                                                                <div className="form-check custom-checkbox">
                                                                    <input type="checkbox" className="form-check-input" id="customCheckBox100" required />
                                                                    <label className="form-check-label" htmlFor="customCheckBox100"></label>
                                                                </div>
                                                            </td>
                                                            <td><span>#000123456</span></td>
                                                            <td><span>Nov 21th 2020 09:21 AM</span></td>
                                                            <td><span>James Sitepu</span></td>
                                                            <td><span>Corner One St<br /> Park London</span></td>
                                                            <td><span>$ 21,4</span></td>
                                                            <td><span className="badge badge-rounded badge-outline-primary badge-lg">Confirmed</span></td>
                                                            <td>
                                                                <div>
                                                                    <a href="javascript:void(0)" className="btn-link me-1">Track</a>
                                                                    <a href="javascript:void(0)" className="btn-link text-dark ms-1">Cancel</a>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="dropdown">
                                                                    <div className="btn-link" data-bs-toggle="dropdown" >
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#737B8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#737B8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#737B8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        </svg>
                                                                    </div>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <a className="dropdown-item" href="javascript:void(0);">Edit</a>
                                                                        <a className="dropdown-item" href="javascript:void(0);">Delete</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="form-check custom-checkbox">
                                                                    <input type="checkbox" className="form-check-input" id="customCheckBox100" required />
                                                                    <label className="form-check-label" htmlFor="customCheckBox100"></label>
                                                                </div>
                                                            </td>
                                                            <td><span>#000123545</span></td>
                                                            <td><span>Nov 22th 2023 09:21 AM</span></td>
                                                            <td><span>Marquez Silaban</span></td>
                                                            <td><span>Park, Orange St</span></td>
                                                            <td><span>$ 87,4</span></td>
                                                            <td><span className="badge badge-rounded badge-outline-danger badge-lg">Canceled</span></td>
                                                            <td>
                                                                <div>
                                                                    <a href="javascript:void(0)" className="btn-link me-1">View details</a>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="dropdown">
                                                                    <div className="btn-link" data-bs-toggle="dropdown" >
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#737B8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#737B8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#737B8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        </svg>
                                                                    </div>
                                                                    <div className="dropdown-menu dropdown-menu-right" >
                                                                        <a className="dropdown-item" href="javascript:void(0);">Edit</a>
                                                                        <a className="dropdown-item" href="javascript:void(0);">Delete</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="form-check custom-checkbox">
                                                                    <input type="checkbox" className="form-check-input" id="customCheckBox100" required />
                                                                    <label className="form-check-label" htmlFor="customCheckBox100"></label>
                                                                </div>
                                                            </td>
                                                            <td><span>#000123583</span></td>
                                                            <td><span>Nov 22th 2023 09:21 AM</span></td>
                                                            <td><span>Joseph David</span></td>
                                                            <td><span>Center Park St</span></td>
                                                            <td><span>$ 88,4</span></td>
                                                            <td><span className="badge badge-rounded badge-outline-success badge-lg">Pending</span></td>
                                                            <td>
                                                                <div>
                                                                    <a href="javascript:void(0)" className="btn-link me-1">View details</a>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="dropdown">
                                                                    <div className="btn-link" data-bs-toggle="dropdown" >
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#737B8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#737B8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#737B8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        </svg>
                                                                    </div>
                                                                    <div className="dropdown-menu dropdown-menu-right" >
                                                                        <a className="dropdown-item" href="javascript:void(0);">Edit</a>
                                                                        <a className="dropdown-item" href="javascript:void(0);">Delete</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade show" id="delivery-tab-pane" role="tabpanel" aria-labelledby="delivery-tab" >
                                    <div className="card mt-2">
                                        <div className="card-body p-0">
                                            <div className="table-responsive active-projects style-1 ItemsCheckboxSec shorting ">
                                                <table id="empoloyees-tblA" className="table">
                                                    <thead>
                                                        <tr>
                                                            <th>
                                                                <div className="form-check custom-checkbox ms-0">
                                                                    <input type="checkbox" className="form-check-input checkAllInput" id="checkAll2" required />
                                                                    <label className="form-check-label" htmlFor="checkAll2"></label>
                                                                </div>
                                                            </th>
                                                            <th>Booking ID</th>
                                                            <th>Date</th>
                                                            <th>Customer</th>
                                                            <th>Location</th>
                                                            <th>Amount</th>
                                                            <th>Status</th>
                                                            <th>Action</th>
                                                            <th></th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        <tr>
                                                            <td>
                                                                <div className="form-check custom-checkbox">
                                                                    <input type="checkbox" className="form-check-input" id="customCheckBox100" required />
                                                                    <label className="form-check-label" htmlFor="customCheckBox100"></label>
                                                                </div>
                                                            </td>
                                                            <td><span>#002123456</span></td>
                                                            <td><span>Nov 21th 2020 09:21 AM</span></td>
                                                            <td><span>Richard Elijah</span></td>
                                                            <td><span>NA</span></td>
                                                            <td><span>$ 87,4</span></td>
                                                            <td><span className="badge badge-rounded badge-outline-primary badge-lg">Confirmed</span></td>
                                                            <td>
                                                                <div>
                                                                    <a href="javascript:void(0)" className="btn-link me-1">Track</a>
                                                                    <a href="javascript:void(0)" className="btn-link text-dark ms-1">Cancel</a>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="dropdown">
                                                                    <div className="btn-link" data-bs-toggle="dropdown" >
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#737B8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#737B8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#737B8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        </svg>
                                                                    </div>
                                                                    <div className="dropdown-menu dropdown-menu-right">
                                                                        <a className="dropdown-item" href="javascript:void(0);">Edit</a>
                                                                        <a className="dropdown-item" href="javascript:void(0);">Delete</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <div className="form-check custom-checkbox">
                                                                    <input type="checkbox" className="form-check-input" id="customCheckBox100" required />
                                                                    <label className="form-check-label" htmlFor="customCheckBox100"></label>
                                                                </div>
                                                            </td>
                                                            <td><span>#000223543</span></td>
                                                            <td><span>Nov 22th 2023 09:21 AM</span></td>
                                                            <td><span>Robert Silaban</span></td>
                                                            <td><span>CA</span></td>
                                                            <td><span>$ 58,4</span></td>
                                                            <td><span className="badge badge-rounded badge-outline-danger badge-lg">Canceled</span></td>
                                                            <td>
                                                                <div>
                                                                    <a href="javascript:void(0)" className="btn-link me-1">View details</a>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                <div className="dropdown">
                                                                    <div className="btn-link" data-bs-toggle="dropdown" >
                                                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                            <path d="M11 12C11 12.5523 11.4477 13 12 13C12.5523 13 13 12.5523 13 12C13 11.4477 12.5523 11 12 11C11.4477 11 11 11.4477 11 12Z" stroke="#737B8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M18 12C18 12.5523 18.4477 13 19 13C19.5523 13 20 12.5523 20 12C20 11.4477 19.5523 11 19 11C18.4477 11 18 11.4477 18 12Z" stroke="#737B8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                            <path d="M4 12C4 12.5523 4.44772 13 5 13C5.55228 13 6 12.5523 6 12C6 11.4477 5.55228 11 5 11C4.44772 11 4 11.4477 4 12Z" stroke="#737B8B" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                                                                        </svg>
                                                                    </div>
                                                                    <div className="dropdown-menu dropdown-menu-right" >
                                                                        <a className="dropdown-item" href="javascript:void(0);">Edit</a>
                                                                        <a className="dropdown-item" href="javascript:void(0);">Delete</a>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                    
                            </div>
                        </div>
                    </div>


                </div> */}
                {/*         <div className="col-xl-12">
                    <div className="card">
                        <div className="card-header border-0 flex-wrap">
                            <h3 className="h-title">Favourites Menus</h3>
                            <ul className="revnue-tab-1 nav nav-tabs ms-auto me-3" id="myTab" role="tablist">
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link active" id="categories-tab" data-bs-toggle="tab" data-bs-target="#categories-tab-pane" type="button" role="tab" aria-controls="categories-tab-pane" aria-selected="true">All Categories</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="breakfast-tab" data-bs-toggle="tab" data-bs-target="#breakfast-tab-pane" type="button" role="tab" aria-controls="breakfast-tab-pane" aria-selected="false">Breakfast Menus</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="menus-tab" data-bs-toggle="tab" data-bs-target="#menus-tab-pane" type="button" role="tab" aria-controls="menus-tab-pane" aria-selected="false">Lunch Menus</button>
                                </li>
                                <li className="nav-item" role="presentation">
                                    <button className="nav-link" id="dinner-tab" data-bs-toggle="tab" data-bs-target="#dinner-tab-pane" type="button" role="tab" aria-controls="dinner-tab-pane" aria-selected="false">Dinner Menus</button>
                                </li>
                            </ul>
                            <div>
                                <select className="default-select h-select" defaultValue="0" aria-label="Default select example">
                                    <option value="0">Week</option>
                                    <option value="1">Month</option>
                                    <option value="2">Daily</option>

                                </select>
                                <div className="dropdown custom-dropdown mb-0">
                                    <div className="btn sharp tp-btn dark-btn" data-bs-toggle="dropdown">
                                        <svg width="5" height="18" viewBox="0 0 5 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="2.25748" cy="2.19083" r="1.92398" fill="#1921FA" />
                                            <circle cx="2.25748" cy="8.92471" r="1.92398" fill="#1921FA" />
                                            <circle cx="2.25748" cy="15.6585" r="1.92398" fill="#1921FA" />
                                        </svg>

                                    </div>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a className="dropdown-item" href="javascript:void(0);">Details</a>
                                        <a className="dropdown-item text-danger" href="javascript:void(0);">Cancel</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="card-body pt-0">
                            <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="categories-tab-pane" role="tabpanel" aria-labelledby="categories-tab" >
                                    <div className="row">
                                        <div className="col-xl-8 col-md-7 col-sm-6">
                                            <div className="fav-box">
                                                <div className="fav-media" style={{ backgroundImage: "url(/images/favirate-img/1.png)" }}>
                                                    <ul className="dz-badge-list">
                                                        <li>
                                                            <a href="javascript:void(0);" className="badge badge-warning badge-sm"><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.9252 4.92282C12.8549 4.71309 12.6729 4.56048 12.4541 4.52772L8.69635 3.95363L7.01 0.361883C6.91293 0.155386 6.7053 0.0235596 6.47712 0.0235596C6.24895 0.0235596 6.04132 0.155386 5.94425 0.361883L4.25789 3.95363L0.500099 4.52772C0.281881 4.56099 0.100597 4.71351 0.0304959 4.92282C-0.0396053 5.13213 0.0132523 5.36307 0.16742 5.52105L2.90598 8.32497L2.25829 12.2924C2.22223 12.5145 2.31595 12.7378 2.49977 12.8676C2.68358 12.9975 2.92534 13.0112 3.12266 12.903L6.47889 11.0494L9.83511 12.903C10.0324 13.0121 10.2748 12.999 10.4592 12.8691C10.6435 12.7393 10.7376 12.5155 10.7013 12.293L10.0536 8.32556L12.7904 5.52105C12.9438 5.36263 12.9958 5.13174 12.9252 4.92282Z" fill="white" />
                                                            </svg><span className="fav-tag">4.8</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <ul className="dz-info-list">
                                                        <li>
                                                            <a href="ecom-product-detail.html" className="title font-w700 text-white">Spaghetti Italiano With Mozarella Cheese</a>
                                                            <p className="text-white mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                                                        </li>
                                                        <ul className="d-flex align-items-center justify-content-between mb-3">
                                                            <li className="text-success"><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.59326 0.906738C3.39962 0.906738 0 4.30636 0 8.5C0 12.6936 3.39962 16.0933 7.59326 16.0933C11.7869 16.0933 15.1865 12.6936 15.1865 8.5C15.1816 4.30841 11.7849 0.911682 7.59326 0.906738ZM7.59326 14.7127C4.1621 14.7127 1.38059 11.9312 1.38059 8.5C1.38059 5.06884 4.1621 2.28733 7.59326 2.28733C11.0244 2.28733 13.8059 5.06884 13.8059 8.5C13.8021 11.9296 11.0228 14.7089 7.59326 14.7127Z" fill="#3CD860" />
                                                                <path d="M10.5781 6.26546L6.92368 9.61547L5.32012 8.01191C5.05062 7.74226 4.61355 7.74226 4.34397 8.01184C4.07439 8.28142 4.07439 8.71849 4.34397 8.98807L6.41493 11.0589C6.67629 11.3203 7.09717 11.3294 7.36961 11.0796L11.5114 7.28296C11.7924 7.02524 11.8112 6.58855 11.5535 6.30757C11.2958 6.0266 10.8591 6.00775 10.5781 6.26546Z" fill="#3CD860" />
                                                            </svg>
                                                                456 Served
                                                            </li>
                                                            <li><h3 className="text-white mb-0">$95</h3></li>
                                                        </ul>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-md-5 col-sm-6">
                                            <div className="fav-box">
                                                <div className="fav-media" style={{ backgroundImage: "url(/images/favirate-img/2.png)" }}>
                                                    <ul className="dz-badge-list">
                                                        <li>
                                                            <a href="javascript:void(0);" className="badge badge-warning badge-sm"><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.9252 4.92282C12.8549 4.71309 12.6729 4.56048 12.4541 4.52772L8.69635 3.95363L7.01 0.361883C6.91293 0.155386 6.7053 0.0235596 6.47712 0.0235596C6.24895 0.0235596 6.04132 0.155386 5.94425 0.361883L4.25789 3.95363L0.500099 4.52772C0.281881 4.56099 0.100597 4.71351 0.0304959 4.92282C-0.0396053 5.13213 0.0132523 5.36307 0.16742 5.52105L2.90598 8.32497L2.25829 12.2924C2.22223 12.5145 2.31595 12.7378 2.49977 12.8676C2.68358 12.9975 2.92534 13.0112 3.12266 12.903L6.47889 11.0494L9.83511 12.903C10.0324 13.0121 10.2748 12.999 10.4592 12.8691C10.6435 12.7393 10.7376 12.5155 10.7013 12.293L10.0536 8.32556L12.7904 5.52105C12.9438 5.36263 12.9958 5.13174 12.9252 4.92282Z" fill="white" />
                                                            </svg><span className="fav-tag">4.8</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <ul className="dz-info-list">
                                                        <li>
                                                            <a href="ecom-product-detail.html" className="title font-w700 text-white">Baked Bread with Ice Cream</a>
                                                            <p className="text-white mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
                                                        </li>
                                                        <ul className="d-flex align-items-center justify-content-between mb-3">
                                                            <li className="text-success"><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.59326 0.906738C3.39962 0.906738 0 4.30636 0 8.5C0 12.6936 3.39962 16.0933 7.59326 16.0933C11.7869 16.0933 15.1865 12.6936 15.1865 8.5C15.1816 4.30841 11.7849 0.911682 7.59326 0.906738ZM7.59326 14.7127C4.1621 14.7127 1.38059 11.9312 1.38059 8.5C1.38059 5.06884 4.1621 2.28733 7.59326 2.28733C11.0244 2.28733 13.8059 5.06884 13.8059 8.5C13.8021 11.9296 11.0228 14.7089 7.59326 14.7127Z" fill="#3CD860" />
                                                                <path d="M10.5781 6.26546L6.92368 9.61547L5.32012 8.01191C5.05062 7.74226 4.61355 7.74226 4.34397 8.01184C4.07439 8.28142 4.07439 8.71849 4.34397 8.98807L6.41493 11.0589C6.67629 11.3203 7.09717 11.3294 7.36961 11.0796L11.5114 7.28296C11.7924 7.02524 11.8112 6.58855 11.5535 6.30757C11.2958 6.0266 10.8591 6.00775 10.5781 6.26546Z" fill="#3CD860" />
                                                            </svg>
                                                                456 Served
                                                            </li>
                                                            <li><h3 className="text-white mb-0">$86</h3></li>
                                                        </ul>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="breakfast-tab-pane" role="tabpanel" aria-labelledby="breakfast-tab" >
                                    <div className="row">
                                        <div className="col-xl-8 col-md-7 col-sm-6">
                                            <div className="fav-box">
                                                <div className="fav-media" style={{ backgroundImage: "url(/images/favirate-img/1.png)" }}>
                                                    <ul className="dz-badge-list">
                                                        <li>
                                                            <a href="javascript:void(0);" className="badge badge-warning badge-sm"><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.9252 4.92282C12.8549 4.71309 12.6729 4.56048 12.4541 4.52772L8.69635 3.95363L7.01 0.361883C6.91293 0.155386 6.7053 0.0235596 6.47712 0.0235596C6.24895 0.0235596 6.04132 0.155386 5.94425 0.361883L4.25789 3.95363L0.500099 4.52772C0.281881 4.56099 0.100597 4.71351 0.0304959 4.92282C-0.0396053 5.13213 0.0132523 5.36307 0.16742 5.52105L2.90598 8.32497L2.25829 12.2924C2.22223 12.5145 2.31595 12.7378 2.49977 12.8676C2.68358 12.9975 2.92534 13.0112 3.12266 12.903L6.47889 11.0494L9.83511 12.903C10.0324 13.0121 10.2748 12.999 10.4592 12.8691C10.6435 12.7393 10.7376 12.5155 10.7013 12.293L10.0536 8.32556L12.7904 5.52105C12.9438 5.36263 12.9958 5.13174 12.9252 4.92282Z" fill="white" />
                                                            </svg><span className="fav-tag">4.8</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <ul className="dz-info-list">
                                                        <li>
                                                            <a href="javascript:void(0);" className="title font-w700 text-white">Spaghetti Italiano With Mozarella Cheese</a>
                                                            <p className="text-white mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                                                        </li>
                                                        <ul className="d-flex align-items-center justify-content-between mb-3">
                                                            <li className="text-success"><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.59326 0.906738C3.39962 0.906738 0 4.30636 0 8.5C0 12.6936 3.39962 16.0933 7.59326 16.0933C11.7869 16.0933 15.1865 12.6936 15.1865 8.5C15.1816 4.30841 11.7849 0.911682 7.59326 0.906738ZM7.59326 14.7127C4.1621 14.7127 1.38059 11.9312 1.38059 8.5C1.38059 5.06884 4.1621 2.28733 7.59326 2.28733C11.0244 2.28733 13.8059 5.06884 13.8059 8.5C13.8021 11.9296 11.0228 14.7089 7.59326 14.7127Z" fill="#3CD860" />
                                                                <path d="M10.5781 6.26546L6.92368 9.61547L5.32012 8.01191C5.05062 7.74226 4.61355 7.74226 4.34397 8.01184C4.07439 8.28142 4.07439 8.71849 4.34397 8.98807L6.41493 11.0589C6.67629 11.3203 7.09717 11.3294 7.36961 11.0796L11.5114 7.28296C11.7924 7.02524 11.8112 6.58855 11.5535 6.30757C11.2958 6.0266 10.8591 6.00775 10.5781 6.26546Z" fill="#3CD860" />
                                                            </svg>
                                                                456 Served
                                                            </li>
                                                            <li><h3 className="text-white mb-0">$8,6</h3></li>
                                                        </ul>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-md-5 col-sm-6">
                                            <div className="fav-box">
                                                <div className="fav-media" style={{ backgroundImage: "url(/images/favirate-img/2.png)" }}>
                                                    <ul className="dz-badge-list">
                                                        <li>
                                                            <a href="javascript:void(0);" className="badge badge-warning badge-sm"><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.9252 4.92282C12.8549 4.71309 12.6729 4.56048 12.4541 4.52772L8.69635 3.95363L7.01 0.361883C6.91293 0.155386 6.7053 0.0235596 6.47712 0.0235596C6.24895 0.0235596 6.04132 0.155386 5.94425 0.361883L4.25789 3.95363L0.500099 4.52772C0.281881 4.56099 0.100597 4.71351 0.0304959 4.92282C-0.0396053 5.13213 0.0132523 5.36307 0.16742 5.52105L2.90598 8.32497L2.25829 12.2924C2.22223 12.5145 2.31595 12.7378 2.49977 12.8676C2.68358 12.9975 2.92534 13.0112 3.12266 12.903L6.47889 11.0494L9.83511 12.903C10.0324 13.0121 10.2748 12.999 10.4592 12.8691C10.6435 12.7393 10.7376 12.5155 10.7013 12.293L10.0536 8.32556L12.7904 5.52105C12.9438 5.36263 12.9958 5.13174 12.9252 4.92282Z" fill="white" />
                                                            </svg><span className="fav-tag">4.8</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <ul className="dz-info-list">
                                                        <li>
                                                            <a href="javascript:void(0);" className="title font-w700 text-white">Baked Bread with Ice Cream</a>
                                                            <p className="text-white mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                                        </li>
                                                        <ul className="d-flex align-items-center justify-content-between mb-3">
                                                            <li className="text-success"><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.59326 0.906738C3.39962 0.906738 0 4.30636 0 8.5C0 12.6936 3.39962 16.0933 7.59326 16.0933C11.7869 16.0933 15.1865 12.6936 15.1865 8.5C15.1816 4.30841 11.7849 0.911682 7.59326 0.906738ZM7.59326 14.7127C4.1621 14.7127 1.38059 11.9312 1.38059 8.5C1.38059 5.06884 4.1621 2.28733 7.59326 2.28733C11.0244 2.28733 13.8059 5.06884 13.8059 8.5C13.8021 11.9296 11.0228 14.7089 7.59326 14.7127Z" fill="#3CD860" />
                                                                <path d="M10.5781 6.26546L6.92368 9.61547L5.32012 8.01191C5.05062 7.74226 4.61355 7.74226 4.34397 8.01184C4.07439 8.28142 4.07439 8.71849 4.34397 8.98807L6.41493 11.0589C6.67629 11.3203 7.09717 11.3294 7.36961 11.0796L11.5114 7.28296C11.7924 7.02524 11.8112 6.58855 11.5535 6.30757C11.2958 6.0266 10.8591 6.00775 10.5781 6.26546Z" fill="#3CD860" />
                                                            </svg>
                                                                456 Served
                                                            </li>
                                                            <li><h3 className="text-white mb-0">$8,6</h3></li>
                                                        </ul>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="menus-tab-pane" role="tabpanel" aria-labelledby="menus-tab" >
                                    <div className="row">
                                        <div className="col-xl-8 col-md-7 col-sm-6">
                                            <div className="fav-box">
                                                <div className="fav-media" style={{ backgroundImage: "url(/images/favirate-img/1.png)" }}>
                                                    <ul className="dz-badge-list">
                                                        <li>
                                                            <a href="javascript:void(0);" className="badge badge-warning badge-sm"><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.9252 4.92282C12.8549 4.71309 12.6729 4.56048 12.4541 4.52772L8.69635 3.95363L7.01 0.361883C6.91293 0.155386 6.7053 0.0235596 6.47712 0.0235596C6.24895 0.0235596 6.04132 0.155386 5.94425 0.361883L4.25789 3.95363L0.500099 4.52772C0.281881 4.56099 0.100597 4.71351 0.0304959 4.92282C-0.0396053 5.13213 0.0132523 5.36307 0.16742 5.52105L2.90598 8.32497L2.25829 12.2924C2.22223 12.5145 2.31595 12.7378 2.49977 12.8676C2.68358 12.9975 2.92534 13.0112 3.12266 12.903L6.47889 11.0494L9.83511 12.903C10.0324 13.0121 10.2748 12.999 10.4592 12.8691C10.6435 12.7393 10.7376 12.5155 10.7013 12.293L10.0536 8.32556L12.7904 5.52105C12.9438 5.36263 12.9958 5.13174 12.9252 4.92282Z" fill="white" />
                                                            </svg><span className="fav-tag">4.8</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <ul className="dz-info-list">
                                                        <li>
                                                            <a href="ecom-product-detail.html" className="title font-w700 text-white">Spaghetti Italiano With Mozarella Cheese</a>
                                                            <p className="text-white mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                                                        </li>
                                                        <ul className="d-flex align-items-center justify-content-between mb-3">
                                                            <li className="text-success"><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.59326 0.906738C3.39962 0.906738 0 4.30636 0 8.5C0 12.6936 3.39962 16.0933 7.59326 16.0933C11.7869 16.0933 15.1865 12.6936 15.1865 8.5C15.1816 4.30841 11.7849 0.911682 7.59326 0.906738ZM7.59326 14.7127C4.1621 14.7127 1.38059 11.9312 1.38059 8.5C1.38059 5.06884 4.1621 2.28733 7.59326 2.28733C11.0244 2.28733 13.8059 5.06884 13.8059 8.5C13.8021 11.9296 11.0228 14.7089 7.59326 14.7127Z" fill="#3CD860" />
                                                                <path d="M10.5781 6.26546L6.92368 9.61547L5.32012 8.01191C5.05062 7.74226 4.61355 7.74226 4.34397 8.01184C4.07439 8.28142 4.07439 8.71849 4.34397 8.98807L6.41493 11.0589C6.67629 11.3203 7.09717 11.3294 7.36961 11.0796L11.5114 7.28296C11.7924 7.02524 11.8112 6.58855 11.5535 6.30757C11.2958 6.0266 10.8591 6.00775 10.5781 6.26546Z" fill="#3CD860" />
                                                            </svg>
                                                                456 Served
                                                            </li>
                                                            <li><h3 className="text-white mb-0">$8,6</h3></li>
                                                        </ul>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-md-5 col-sm-6">
                                            <div className="fav-box">
                                                <div className="fav-media" style={{ backgroundImage: "url(/images/favirate-img/2.png)" }}>
                                                    <ul className="dz-badge-list">
                                                        <li>
                                                            <a href="javascript:void(0);" className="badge badge-warning badge-sm"><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.9252 4.92282C12.8549 4.71309 12.6729 4.56048 12.4541 4.52772L8.69635 3.95363L7.01 0.361883C6.91293 0.155386 6.7053 0.0235596 6.47712 0.0235596C6.24895 0.0235596 6.04132 0.155386 5.94425 0.361883L4.25789 3.95363L0.500099 4.52772C0.281881 4.56099 0.100597 4.71351 0.0304959 4.92282C-0.0396053 5.13213 0.0132523 5.36307 0.16742 5.52105L2.90598 8.32497L2.25829 12.2924C2.22223 12.5145 2.31595 12.7378 2.49977 12.8676C2.68358 12.9975 2.92534 13.0112 3.12266 12.903L6.47889 11.0494L9.83511 12.903C10.0324 13.0121 10.2748 12.999 10.4592 12.8691C10.6435 12.7393 10.7376 12.5155 10.7013 12.293L10.0536 8.32556L12.7904 5.52105C12.9438 5.36263 12.9958 5.13174 12.9252 4.92282Z" fill="white" />
                                                            </svg><span className="fav-tag">4.8</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <ul className="dz-info-list">
                                                        <li>
                                                            <a href="ecom-product-detail.html" className="title font-w700 text-white">Baked Bread with Ice Cream</a>
                                                            <p className="text-white mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                                        </li>
                                                        <ul className="d-flex align-items-center justify-content-between mb-3">
                                                            <li className="text-success"><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.59326 0.906738C3.39962 0.906738 0 4.30636 0 8.5C0 12.6936 3.39962 16.0933 7.59326 16.0933C11.7869 16.0933 15.1865 12.6936 15.1865 8.5C15.1816 4.30841 11.7849 0.911682 7.59326 0.906738ZM7.59326 14.7127C4.1621 14.7127 1.38059 11.9312 1.38059 8.5C1.38059 5.06884 4.1621 2.28733 7.59326 2.28733C11.0244 2.28733 13.8059 5.06884 13.8059 8.5C13.8021 11.9296 11.0228 14.7089 7.59326 14.7127Z" fill="#3CD860" />
                                                                <path d="M10.5781 6.26546L6.92368 9.61547L5.32012 8.01191C5.05062 7.74226 4.61355 7.74226 4.34397 8.01184C4.07439 8.28142 4.07439 8.71849 4.34397 8.98807L6.41493 11.0589C6.67629 11.3203 7.09717 11.3294 7.36961 11.0796L11.5114 7.28296C11.7924 7.02524 11.8112 6.58855 11.5535 6.30757C11.2958 6.0266 10.8591 6.00775 10.5781 6.26546Z" fill="#3CD860" />
                                                            </svg>
                                                                456 Served
                                                            </li>
                                                            <li><h3 className="text-white mb-0">$8,6</h3></li>
                                                        </ul>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tab-pane fade" id="dinner-tab-pane" role="tabpanel" aria-labelledby="dinner-tab">
                                    <div className="row">
                                        <div className="col-xl-8 col-md-7 col-sm-6">
                                            <div className="fav-box">
                                                <div className="fav-media" style={{ backgroundImage: "url(/images/favirate-img/1.png)" }}>
                                                    <ul className="dz-badge-list">
                                                        <li>
                                                            <a href="javascript:void(0);" className="badge badge-warning badge-sm"><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.9252 4.92282C12.8549 4.71309 12.6729 4.56048 12.4541 4.52772L8.69635 3.95363L7.01 0.361883C6.91293 0.155386 6.7053 0.0235596 6.47712 0.0235596C6.24895 0.0235596 6.04132 0.155386 5.94425 0.361883L4.25789 3.95363L0.500099 4.52772C0.281881 4.56099 0.100597 4.71351 0.0304959 4.92282C-0.0396053 5.13213 0.0132523 5.36307 0.16742 5.52105L2.90598 8.32497L2.25829 12.2924C2.22223 12.5145 2.31595 12.7378 2.49977 12.8676C2.68358 12.9975 2.92534 13.0112 3.12266 12.903L6.47889 11.0494L9.83511 12.903C10.0324 13.0121 10.2748 12.999 10.4592 12.8691C10.6435 12.7393 10.7376 12.5155 10.7013 12.293L10.0536 8.32556L12.7904 5.52105C12.9438 5.36263 12.9958 5.13174 12.9252 4.92282Z" fill="white" />
                                                            </svg><span className="fav-tag">4.8</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <ul className="dz-info-list">
                                                        <li>
                                                            <a href="ecom-product-detail.html" className="title font-w700 text-white">Spaghetti Italiano With Mozarella Cheese</a>
                                                            <p className="text-white mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam</p>
                                                        </li>
                                                        <ul className="d-flex align-items-center justify-content-between mb-3">
                                                            <li className="text-success"><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.59326 0.906738C3.39962 0.906738 0 4.30636 0 8.5C0 12.6936 3.39962 16.0933 7.59326 16.0933C11.7869 16.0933 15.1865 12.6936 15.1865 8.5C15.1816 4.30841 11.7849 0.911682 7.59326 0.906738ZM7.59326 14.7127C4.1621 14.7127 1.38059 11.9312 1.38059 8.5C1.38059 5.06884 4.1621 2.28733 7.59326 2.28733C11.0244 2.28733 13.8059 5.06884 13.8059 8.5C13.8021 11.9296 11.0228 14.7089 7.59326 14.7127Z" fill="#3CD860" />
                                                                <path d="M10.5781 6.26546L6.92368 9.61547L5.32012 8.01191C5.05062 7.74226 4.61355 7.74226 4.34397 8.01184C4.07439 8.28142 4.07439 8.71849 4.34397 8.98807L6.41493 11.0589C6.67629 11.3203 7.09717 11.3294 7.36961 11.0796L11.5114 7.28296C11.7924 7.02524 11.8112 6.58855 11.5535 6.30757C11.2958 6.0266 10.8591 6.00775 10.5781 6.26546Z" fill="#3CD860" />
                                                            </svg>
                                                                456 Served
                                                            </li>
                                                            <li><h3 className="text-white mb-0">$8,6</h3></li>
                                                        </ul>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-md-5 col-sm-6">
                                            <div className="fav-box">
                                                <div className="fav-media" style={{ backgroundImage: "url(images/favirate-img/2.png)" }}>
                                                    <ul className="dz-badge-list">
                                                        <li>
                                                            <a href="javascript:void(0);" className="badge badge-warning badge-sm"><svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M12.9252 4.92282C12.8549 4.71309 12.6729 4.56048 12.4541 4.52772L8.69635 3.95363L7.01 0.361883C6.91293 0.155386 6.7053 0.0235596 6.47712 0.0235596C6.24895 0.0235596 6.04132 0.155386 5.94425 0.361883L4.25789 3.95363L0.500099 4.52772C0.281881 4.56099 0.100597 4.71351 0.0304959 4.92282C-0.0396053 5.13213 0.0132523 5.36307 0.16742 5.52105L2.90598 8.32497L2.25829 12.2924C2.22223 12.5145 2.31595 12.7378 2.49977 12.8676C2.68358 12.9975 2.92534 13.0112 3.12266 12.903L6.47889 11.0494L9.83511 12.903C10.0324 13.0121 10.2748 12.999 10.4592 12.8691C10.6435 12.7393 10.7376 12.5155 10.7013 12.293L10.0536 8.32556L12.7904 5.52105C12.9438 5.36263 12.9958 5.13174 12.9252 4.92282Z" fill="white" />
                                                            </svg><span className="fav-tag">4.8</span>
                                                            </a>
                                                        </li>
                                                    </ul>
                                                    <ul className="dz-info-list">
                                                        <li>
                                                            <a href="ecom-product-detail.html" className="title font-w700 text-white">Baked Bread with Ice Cream</a>
                                                            <p className="text-white mt-2">Lorem ipsum dolor sit amet, consectetur adipiscing elit</p>
                                                        </li>
                                                        <ul className="d-flex align-items-center justify-content-between mb-3">
                                                            <li className="text-success"><svg width="16" height="17" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <path d="M7.59326 0.906738C3.39962 0.906738 0 4.30636 0 8.5C0 12.6936 3.39962 16.0933 7.59326 16.0933C11.7869 16.0933 15.1865 12.6936 15.1865 8.5C15.1816 4.30841 11.7849 0.911682 7.59326 0.906738ZM7.59326 14.7127C4.1621 14.7127 1.38059 11.9312 1.38059 8.5C1.38059 5.06884 4.1621 2.28733 7.59326 2.28733C11.0244 2.28733 13.8059 5.06884 13.8059 8.5C13.8021 11.9296 11.0228 14.7089 7.59326 14.7127Z" fill="#3CD860" />
                                                                <path d="M10.5781 6.26546L6.92368 9.61547L5.32012 8.01191C5.05062 7.74226 4.61355 7.74226 4.34397 8.01184C4.07439 8.28142 4.07439 8.71849 4.34397 8.98807L6.41493 11.0589C6.67629 11.3203 7.09717 11.3294 7.36961 11.0796L11.5114 7.28296C11.7924 7.02524 11.8112 6.58855 11.5535 6.30757C11.2958 6.0266 10.8591 6.00775 10.5781 6.26546Z" fill="#3CD860" />
                                                            </svg>
                                                                456 Served
                                                            </li>
                                                            <li><h3 className="text-white mb-0">$8,6</h3></li>
                                                        </ul>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              
                            </div>
                        </div>
                    </div>
                </div> */}
            </div>
        </div > : <div style={{ height: 500, display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>

            <InfinitySpin
                width='200'
                color="rgb(69, 39, 160)"
            />
        </div>}


        <div className="modal fade" id="exampleModal1" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header ">
                        <h5 className="modal-title">Add Customer</h5>
                        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <div className="form-group">
                            <label className="form-label">Seller Mobile Number</label>
                            <input type="number" className="form-control mb-3" id="exampleInputEmail1" placeholder="Number" />
                            <label className="form-label">Email</label>
                            <input type="email" className="form-control mb-3" id="exampleInputEmail2" placeholder=" Email" />
                            <label className="form-label">Amount</label>
                            <input type="number" className="form-control mb-3" id="exampleInputEmail3" placeholder="Amount" />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-danger light" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary">Save changes</button>
                    </div>
                </div>

            </div>
        </div></>
}