import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router"
import { resolveRequest } from "../../helpers"
import { addWorkingSystem, listingWorkingSystem } from "../../apis/workingSystem"
import { getLang } from "../../helpers/localizationHelper"
import { formikFieldValidation, formikSetup, initialValues, validationSchema } from "../../helpers/formik"
import { useFormik } from "formik"
import { AppButton } from "../../components/button"
import { AppPagination } from "../../components/listing/pagination"
import { AppModal } from "../../components/modal"
import { GetAllWorkingSystemsTypes } from "../../apis/lookups"
import { AppInput } from "../../components/formsComponents/input"
import { AppDropdownlist } from "../../components/formsComponents/dropdownlist"
import { Loader } from "../../components/loader"
import { WorkingSystemDataTable } from "./components/workingSystemDataTable"

export const WorkingSystemScreen = () => {

    const navigate = useNavigate()
    const { t } = useTranslation()
    const [addModal, setAddModal] = useState(false)
    const [requestDone, setRequestDone] = useState(false)

    const [isLoading, setLoading] = useState(false)
    const [allWorkingSystems, setAllWorkingSystems] = useState<any>()
    const [allWorkingSystemTypes, setAllWorkingSystemTypes] = useState<any>()

    const [currentPage, setCurrentPage] = useState(1);

    useEffect(() => {
        resolveRequest(GetAllWorkingSystemsTypes, setAllWorkingSystemTypes)



    }, [getLang()])

    useEffect(() => {
        (async () => {
            const res = await listingWorkingSystem({ pageNumber: currentPage, pageSize: 10 })
            setAllWorkingSystems(res)
        })()

    }, [requestDone, getLang()])
    const submit = async (values: any) => {

        setLoading(true)
        try {
            const res = await addWorkingSystem({ ...values, })
            if (res?.status) {
                resetForm({ values: {} })
                navigate(`/admin/workingSystems/${res?.data}`)
                setAddModal(false)



            }
        } catch (e) {

        }
        setLoading(false)
    };

    const formik = useFormik(
        formikSetup(initialValues, undefined, validationSchema, 'addWorkingSystem', submit),
    );
    const { handleChange, handleSubmit, values, touched, errors, setFieldValue, setFieldError, resetForm } = formik;
    const isValid = formikFieldValidation(touched, errors);

    return <><div className="pe-5 ps-5 pt-5" style={{ position: 'relative' }}>

        <div className="card p-3 mt-4 pb-1 pt-1">
            <div className="d-flex justify-content-between align-items-center">
                <p className="commonHeader">
                    {t('workingSystems')}
                </p>
                <AppButton outlined title={t("add_workingSystem")} onClick={() => {
                    setAddModal(true)
                }} />

            </div>
        </div>
        <div className="card p-3" style={{ marginTop: -15 }}>

            <WorkingSystemDataTable setRequestDone={setRequestDone} thead={["id", "title", "maxFutureDays", "startDate", "endDate", "isActive", "isDefault"]} td={allWorkingSystems?.data} />

            {allWorkingSystems?.data?.length != 0 && <AppPagination currentPage={currentPage} pageCount={Math.ceil(allWorkingSystems?.totalRows / 10)} setCurrentPage={setCurrentPage} />
            }
        </div>
        <AppModal
            className="modal2"

            show={addModal}
            onClose={() => {
                setAddModal(false)
                resetForm({ values: {} })

            }}
            body={
                <div className="row">
                    <AppInput label={t('title')} require onChange={handleChange('title')} errorMessage={isValid('title')} value={values?.title} />
                    <AppDropdownlist errorMessage={isValid('workingSystemTypeId')} require
                        label={t('workingSystemType')} onChange={(val) => {
                            setFieldValue('workingSystemTypeId', val?.target?.value)
                        }} items={allWorkingSystemTypes} value={values?.workingSystemTypeId} />

                </div>
            } title={t('add_workingSystem_modal')} onSaveClick={handleSubmit} />

    </div>
        {isLoading && <Loader />}
    </>

}