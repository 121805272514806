import { useTranslation } from "react-i18next"
import { getLang } from "../../helpers/localizationHelper"
//@ts-ignore
import TimeInput from 'material-ui-time-picker'

export const AppTimepicker = ({ label, value, onChange, placeholder, errorMessage, require = false, }: { require?: boolean, errorMessage?: string, label?: string, placeholder?: string, value: any, onChange: (val: any) => void }) => {
    const { t } = useTranslation()
    return <div className="col-xl-6 col-lg-6 mb-3">



        {label &&
            <h4 className="card-title">
                {require ? <span style={{ color: 'red' }}> * </span> : null}{" "}
                {label}</h4>
        }

        <TimeInput
            className="form-control mt-3"
            locale="en"
            mode='12h'
            okLabel={t('save')}
            cancelLabel={t('close')}
            value={value}
            onChange={onChange}
        />
        {/*  <input type={time == false ? "date" : "time"} placeholder={placeholder} value={value} onChange={(event) => {
            onChange?.(event.target.value)
        }} className="form-control mt-3" /> */}
        {errorMessage && (
            <p style={{ marginTop: 5, fontSize: 12, marginBottom: 5, color: "red" }}>
                {errorMessage}
            </p>
        )}

    </div>
}