import { useTranslation } from "react-i18next"


export const AppRadioButtons = ({ buttons }: { buttons: any }) => {
    const { t } = useTranslation()
    return <div className="col-xl-6 col-lg-6 col-12 mb-4">
        <h4 className="card-title">
            {t("gender")}</h4>
        <div className="d-flex align-items-center" >
            {!!buttons.length && buttons.map((item: any, index: any) => {
                return <div className="d-flex align-items-center" style={{
                    marginInlineEnd: 20

                }}>
                    <input style={{
                        accentColor: 'rgb(106, 73, 242)',

                    }} checked={item.checked} type="radio" onChange={item.onChange} value={`option${index + 1}`} />
                    <label style={{
                        marginInlineStart: 20,
                        marginTop: 5
                    }}>
                        {item.label}
                    </label>
                </div>
            })}

        </div>
    </div>
}