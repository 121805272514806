import { getLang } from "../../helpers/localizationHelper";
import { SuccessToast } from "../../helpers/toastify";
import history from "../../routing/historyObj";
import axiosCall from "./axiosConfig";
import { interceptorsErrorsResponse } from "./interceptorErrorResponseCases";
import Cookies from "universal-cookie";

axiosCall.interceptors.request.use(
  (req) => {
    const cookies = new Cookies();
    req.headers["Accept-Language"] = getLang();
    req.headers["Authorization"] = cookies.get("token");

    return req;
  },
  (error) => {
    return Promise.reject(error);
  }
);
axiosCall.interceptors.response.use(
  (res) => {
    if (res.config.method == "delete") {
      SuccessToast(res?.data?.data?.message ?? "تم الحذف بنجاح");
    } else if (res.config.method == "put" || res.config.method == "patch") {
      SuccessToast(res?.data?.data?.message);
    } else if (res.config.method == "post") {
      if (
        !res?.config?.url?.includes("Lookup") &&
        !res?.config?.url?.includes("Get") &&
        !res?.config?.url.includes("Login") &&
        !res?.config?.url.includes("Dashboard")
      ) {
        SuccessToast(res?.data?.message);
      }
    }
    return res;
  },
  (error) => {
    const err = { ...error };
    if (!error?.response?.config?.url?.includes("Get")) {
      interceptorsErrorsResponse(err);
    }
    return err;
  }
);
