import { Route, Routes, useLocation, useNavigate } from "react-router";
import { appRoutes } from "./routes";
import PrivateRoute from "./privateRoute";
import { NoMatch } from "./noMatch";
import { axiosInt } from "../apis/axios/axiosInterceptors";
import { useEffect } from "react";
import { LoginScreen } from "../screens/login";
import PublicRoute from "./publicRoute";
import { useDispatch } from "react-redux";
import { getLanguages } from "../redux/actions/languageActions";
import { getLang } from "../helpers/localizationHelper";
import { AdminDashboardScreen } from "../screens/adminDashboard";
import { Navigate } from 'react-router-dom';

const AppRoutes = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { state } = location;
  useEffect(() => {
    dispatch(getLanguages());
  }, []);

  return (
    <Routes>
      <Route
        element={
          <PublicRoute>
            <LoginScreen />
          </PublicRoute>
        }
        path={"/login"}
      ></Route>
      <Route
        exact={true}
        path={"/"}
        element={<PrivateRoute>{ <Navigate to="/admin" />}</PrivateRoute>}
      ></Route>
      {appRoutes.map((item, index) => {
        return (
          <Route
            key={index}
            exact={true}
            path={item.link}
            element={<PrivateRoute>{item.component}</PrivateRoute>}
          ></Route>
        );
      })}
      <Route path={"*"} element={<NoMatch />}></Route>
    </Routes>
  );
};

export default AppRoutes;
