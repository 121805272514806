import axiosCall from "./axios/axiosConfig";
import { endpoints } from "./endpoints";
export const loginAPI = ({ email, password, rememberMe }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.auth.login, {
        email,
        password,
        rememberMe,
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            token: res?.data?.obj?.token,
            time: res?.data?.obj?.expiresOn,
          });
        } else {
          reject(res?.data?.message);
        }
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const userDataApi = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.auth.userData)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj || []);
        } else reject(res?.data?.data || res?.data?.obj || []);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const updatePassword = ({ data }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.auth.changePassword, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else reject(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const updateProfile = ({ data }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.auth.updateProfile, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else reject(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const userProfileData = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.auth.getUserData)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
