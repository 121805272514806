import { useEffect, useState } from "react"

import { useTranslation } from "react-i18next"
import { useLocation, } from "react-router"
import { AppModal } from "../../../components/modal"
import { Loader } from "../../../components/loader"
import { AppInput } from "../../../components/formsComponents/input"
import { useFormik } from "formik"
import { formikFieldValidation, formikSetup, initialValues, validationSchema } from "../../../helpers/formik"
import { resolveRequest } from "../../../helpers"
import { AppDropdownlist } from "../../../components/formsComponents/dropdownlist"
import NoDataIcon from "../../../styles/svg/noDataIcon"
import { assignBranchManager, branchManagerDetails, branchesLookup, editBranchManager } from "../../../apis/branchManagers"
import { assignTenantManager, editTenantManager, tenantManagerDetails } from "../../../apis/tenantManagers"
import { listingRestaurantSubscriptions } from "../../../apis/restaurantSupscriptions"
import { listingRestaurant, listingRestaurantsWithoutPagination } from "../../../apis/restaurants"
import { AppMobileNum } from "../../../components/formsComponents/mobileNum"
import { getLang } from "../../../helpers/localizationHelper"
import { baseURLPhotos } from "../../../apis/endpoints"

export const TenantManagersDataTable = ({ thead, td, setRequestDone }: { thead: any, td: any, setRequestDone: (bool: any) => void }) => {
    const { t } = useTranslation()
    const location = useLocation()
    const [id, setId] = useState(undefined)
    const [userDetails, setUserDetails] = useState<any>()
    const [loading, setLoading] = useState(false)
    const [theadValues, setTheadValues] = useState(thead)
    const [tdValues, setTdValues] = useState<any>()
    const [editModal, setEditModal] = useState(false)
    const [assignModal, setAssignModal] = useState(false)
    const [allBranches, setAllBranches] = useState<any>()
    const transformObj = (keys: Array<string>, data: any) => {
        const newObj: any = {}
        keys?.map((item) => {
            if (data[item] != undefined) {

                newObj[item] = data[item]
            } else {
                newObj[item] = undefined
            }
        })

        return newObj
    }

    useEffect(() => {

        setTheadValues(thead)

    }, [thead])
    useEffect(() => {

        setTdValues(td?.map((item: any) => transformObj(theadValues, item)))
    }, [td])
    const fun_tdValues = (itemValue: any) => {
        return Object.entries(itemValue)?.map((item: any, index: number) => {

            if (item?.[0] != 'id' && item?.[0] != 'profileImage' && item?.[0] != "gender") {
                if (index == 0) {
                    return <td key={index}><strong>{item?.[1] == "" ? '#' : item?.[1]}</strong></td>
                }
                if (item?.[0] == "tenant") {
                    return <td key={index}>{item?.[1]?.title}</td>
                }
                if (item?.[0] == "fullMobile") {
                    return <td style={{ direction: 'ltr', textAlign: getLang() == 'ar-SA' ? 'end' : 'start' }} key={index}>{item?.[1]}</td>
                }

                return <td key={index}>{item?.[1]}</td>
            }
        })
    }

    useEffect(() => {
        resolveRequest(listingRestaurantsWithoutPagination, setAllBranches)
    }, [])

    useEffect(() => {
        if (id)
            resolveRequest(tenantManagerDetails, setUserDetails, { id: id })

    }, [id])
    const submit = async (values: any) => {

        setLoading(true)
        try {
            const res = await assignTenantManager({ ...values, userId: id ?? 0 })
            if (res?.status) {
                //  navigate(`/admin/restaurants/${res?.data}`)
                setAssignModal(false)
                setRequestDone((prev: any) => !prev)


            }
        } catch (e) {

        }
        setLoading(false)
    };

    const formik = useFormik(
        formikSetup(initialValues, userDetails, validationSchema, 'assignTenantManager', submit),
    );

    const { handleChange, handleSubmit, values, touched, errors, setFieldValue, setFieldError, resetForm: resetAssignForm } = formik;
    const isValid = formikFieldValidation(touched, errors);
    const [country, setCountry] = useState<any>()

    const submit2 = async (values: any) => {

        setLoading(true)
        try {
            const res = await editTenantManager({ ...values, id: id ?? 0, dialCode: country })
            if (res?.status) {
                //  navigate(`/admin/restaurants/${res?.data}`)
                setEditModal(false)
                setRequestDone((prev: any) => !prev)
                setId(undefined)

            }
        } catch (e) {

        }
        setLoading(false)
    };

    useEffect(() => {
        if (userDetails)
            setCountry(userDetails?.dialCode)
    }, [userDetails])
    const formik2 = useFormik(
        formikSetup(initialValues, userDetails, validationSchema, 'editTenantManager', submit2),
    );
    const { handleChange: handleChange2, handleSubmit: handleSubmit2, values: values2, touched: touched2, errors: errors2, setFieldValue: setFieldValue2, setFieldError: setFieldError2, resetForm } = formik2;
    const isValid2 = formikFieldValidation(touched2, errors2);

    return <><div className="table-responsive">
        <table className="table table-responsive-md">
            <thead>

                <tr>
                    <th></th>
                    {theadValues?.map((item: string, index: any) => {
                        if (item != 'id' && item != "profileImage" && item != "gender") {
                            return (

                                <th key={index}><strong>{t(item)}</strong></th>

                            )
                        }
                    })}
                    <th key={20}><strong>{t("actions")}</strong></th>


                </tr>
            </thead>
            <tbody>
                {tdValues?.map((tdValue: any, index: any) => {
                    return <tr key={index}>
                        <td><img style={{ borderRadius: '50%' }} width={50} height={50} src={tdValue?.profileImage != "" ? baseURLPhotos + "/" + tdValue?.profileImage : tdValue?.gender == true ? "/woman.jpg" : "/man.jpg"} /></td>

                        {fun_tdValues(tdValue)}
                        <td>
                            <div className="d-flex">
                                <a onClick={() => {
                                    setId(tdValue?.id)

                                    resetForm({ values: {} })
                                    setUserDetails(undefined)
                                    setEditModal(true)


                                }} className="btn btn-primary shadow btn-xs sharp me-1"><i className="fa fa-pencil"></i></a>
                                <a onClick={() => {
                                    setId(tdValue?.id)
                                    resetAssignForm({ values: {} })
                                    setUserDetails(undefined)
                                    setAssignModal(true)

                                }} className="btn btn-danger shadow btn-xs sharp"><i className="fa fa-user"></i></a>
                            </div>
                        </td>
                    </tr>


                })}

            </tbody>
        </table>



        <AppModal
            className="modal2"

            show={editModal}
            onClose={() => {
                setEditModal(false)
                setId(undefined)
            }}
            body={
                <>
                    <div className="row">
                        <AppInput placeholder={t('firstName')} label={t('firstName')} require onChange={handleChange2('firstName')} errorMessage={isValid2('firstName')} value={values2?.firstName} />
                        <AppInput placeholder={t('lastName')} label={t('lastName')} require onChange={handleChange2('lastName')} errorMessage={isValid2('lastName')} value={values2?.lastName} />

                    </div>
                    <div className="row">
                        <AppInput placeholder={t('email')} label={t('email')} disable onChange={handleChange2('email')} errorMessage={isValid2('email')} value={values2?.email} />

                        <AppMobileNum country={country} setCountry={setCountry} placeholder={t('mobile')} label={t('mobile')} require onChange={handleChange2('mobile')} errorMessage={isValid2('mobile')} value={values2?.mobile} />

                    </div>
                </>
            } title={t('edit_tenantManager_modal')} onSaveClick={handleSubmit2} />

        <AppModal
            className="modal2"

            show={assignModal}
            onClose={() => {
                setAssignModal(false)
                setId(undefined)
            }}
            body={
                <>
                    <div className="d-flex justify-content-center">
                        <AppDropdownlist className="col-12" errorMessage={isValid('tenantId')} require placeholder={t('tenantId')} label={t('tenantId')} onChange={(val) => {
                            setFieldValue('tenantId', val?.target?.value)
                        }} items={allBranches} value={values?.tenantId} />
                    </div>

                </>
            } title={t('assign_tenantManager_modal')} onSaveClick={handleSubmit} />



        {loading && <Loader />}

    </div>
        {tdValues?.length > 0 ? <></> : <>
            <NoDataIcon />
        </>}
    </>
}