import { useEffect, useState } from "react"
import { AppButton } from "../../components/button"
import { useTranslation } from "react-i18next"
import { AppModal } from "../../components/modal"
import { useFormik } from "formik"
import { formikFieldValidation, formikSetup, initialValues, validationSchema } from "../../helpers/formik"
import { AppInput } from "../../components/formsComponents/input"
import { AppDropdownlist } from "../../components/formsComponents/dropdownlist"
import { resolveRequest } from "../../helpers"
import { GetAllCountries } from "../../apis/lookups"
import { Loader } from "../../components/loader"
import { addRestaurant, listingRestaurant } from "../../apis/restaurants"
import { useNavigate } from "react-router"
import { AppDataTable } from "./components/restaurant/dataTable"
import { AppPagination } from "../../components/listing/pagination"
import { getLang } from "../../helpers/localizationHelper"

export const TenantListingScreen = () => {

    const navigate = useNavigate()
    const { t } = useTranslation()
    const [addModal, setAddModal] = useState(false)
    const [requestDone, setRequestDone] = useState(false)

    const [isLoading, setLoading] = useState(false)
    const [allCountries, setAllCountries] = useState()
    const [allRestaurants, setAllRestaurants] = useState<any>()
    const [currentPage, setCurrentPage] = useState(1);
    useEffect(() => {
        resolveRequest(GetAllCountries, setAllCountries)



    }, [getLang()])

    useEffect(() => {
        (async () => {
            const res = await listingRestaurant({ pageNumber: currentPage, pageSize: 10 })
            setAllRestaurants(res)
        })()

    }, [currentPage, requestDone, getLang()])
    const submit = async (values: any) => {

        setLoading(true)
        try {
            const res = await addRestaurant(values)
            if (res?.status) {
                resetForm({ values: '' })
                navigate(`/admin/restaurants/${res?.data}`)
                setAddModal(false)




            }
        } catch (e) {

        }
        setLoading(false)
    };

    const formik = useFormik(
        formikSetup(initialValues, undefined, validationSchema, 'addRestaurant', submit),
    );
    const { handleChange, handleSubmit, values, touched, errors, setFieldValue, setFieldError, resetForm } = formik;
    const isValid = formikFieldValidation(touched, errors);

    return <><div className="pe-5 ps-5 pt-5" style={{ position: 'relative' }}>

        <div className="card p-3 mt-4 pb-1 pt-1">
            <div className="d-flex justify-content-between align-items-center">
                <p className="commonHeader">
                    {t('restaurants')}
                </p>
                <AppButton outlined title={t("add_restaurant")} onClick={() => {
                    setAddModal(true)
                }} />

            </div>
        </div>
        <div className="card p-3" style={{ marginTop: -15 }}>

            <AppDataTable setRequestDone={setRequestDone} thead={["code", "id", "title", "endDate", "numberOfBranches", "isActive", "published"]} td={allRestaurants?.data} />
            {allRestaurants?.data?.length != 0 && <AppPagination currentPage={currentPage} pageCount={Math.ceil(allRestaurants?.totalRows / 10)} setCurrentPage={setCurrentPage} />
            }
        </div>
        <AppModal
            className="modal2"
            show={addModal}
            onClose={() => {
                resetForm({ values: '' })
                setAddModal(false)
            }}
            body={
                <div className="row">
                    <AppInput placeholder={t('restaurant_name')} label={t('restaurant_name')} require onChange={handleChange('title')} errorMessage={isValid('title')} value={values?.title} />
                    <AppDropdownlist errorMessage={isValid('countryId')} require placeholder={t('country')} label={t('country')} onChange={(val) => {
                        setFieldValue('countryId', val?.target?.value)
                    }} items={allCountries} value={values?.countryId} />

                </div>
            } title={t('add_restaurant_modal')} onSaveClick={handleSubmit} />

    </div>
        {isLoading && <Loader />}
    </>

}