import axiosCall from "../axios/axiosConfig";
import { endpoints } from "../endpoints";

export const GetAllCountries = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.lookups.countries)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject({ status: false, message: res?.data?.message });
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const GetAllSurveyStyles = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.lookups.surveysStyles)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject({ status: false, message: res?.data?.message });
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const GetAllLanguages = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.lookups.languages)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject({ status: false, message: res?.data?.message });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const GetAllTenantCities = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.lookups.tenantCities)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject({ status: false, message: res?.data?.message });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const GetAllWorkingSystemsTypes = () => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.lookups.workingSystems)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject({ status: false, message: res?.data?.message });
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const GetAllWorkingDaysAdd = ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.workingSystemDays.lookupWeekAdd, { id: id })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else resolve([]);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const GetAllWorkingDaysEdit = ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.workingSystemDays.lookupWeekEdit, { id: id })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj || []);
        } else resolve([]);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
