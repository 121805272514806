import { useEffect, useState } from "react"
import { useParams } from "react-router"
import { getLang } from "../../helpers/localizationHelper"
import { resolveRequest } from "../../helpers"
import { detailsReservations } from "../../apis/reservations"
import { useTranslation } from "react-i18next"
import moment from "moment"
import { Loader } from "../../components/loader"
import { baseURLPhotos } from "../../apis/endpoints"

export const ReservationDetailsScreen = () => {
    const { id } = useParams()
    const { t } = useTranslation()
    const [reservationDetails, setReservationDetails] = useState<any>()
    useEffect(() => {
        resolveRequest(detailsReservations, setReservationDetails, { id: id })
    }, [id, getLang(),])
    const [bookingHistory, setBookingHistory] = useState([{ id: 0, title: t("BookingWasReserved"), dateTime: "09:23 AM" }])

    return <> {reservationDetails ? <div className="container">
        <div className="row">
            <div className="col-xl-9">
                <div className="food-map">


                </div>
                <div className="row">
                    <div className="col-xl-4 col-md-6">
                        <div className="card">
                            <div className="card-header border-0 pb-0">
                                <h4 className="title">{t("reservationStatuss")}</h4>
                            </div>
                            <div className="card-body pt-2 px-0">
                                <div className="history-box dz-scroll" style={{ height: 300 }}>
                                    {bookingHistory?.map((item: any, index: any) => {
                                        return <ul key={index} className="history">
                                            <li >
                                                <div className="history-dot" style={{ left: getLang() == "ar-SA" ? 18 : 18, right: getLang() == "ar-SA" ? 18 : undefined }}></div>
                                                <a className="history-deatils" href="javscript:void(0);">
                                                    <h6 className="font-w600">{t("BookingWasReserved")}</h6>
                                                    <span className="text-primary">{"09:23 AM"}</span>
                                                </a>
                                            </li>
                                        </ul>
                                    })
                                    }

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8 col-md-6">
                        <div className="card">
                            <div className="card-header border-0 pb-0">
                                <h4 className="h-title">{t("items")}</h4>
                                <div className="dropdown custom-dropdown mb-0">
                                    <div className="btn sharp tp-btn dark-btn" data-bs-toggle="dropdown">
                                        <svg width="5" height="18" viewBox="0 0 5 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <circle cx="2.25748" cy="2.19083" r="1.92398" fill="#1921FA" />
                                            <circle cx="2.25748" cy="8.92471" r="1.92398" fill="#1921FA" />
                                            <circle cx="2.25748" cy="15.6585" r="1.92398" fill="#1921FA" />
                                        </svg>

                                    </div>
                                    <div className="dropdown-menu dropdown-menu-right">
                                        <a className="dropdown-item" >{t("details")}</a>
                                        <a className="dropdown-item text-danger">{t("close")}</a>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body pt-2">
                                <div className="food-items-bx">
                                    <div className="food-items-media">
                                        <img src="/images/favirate-img/5.png" alt="" />
                                    </div>
                                    <div className="d-flex align-items-end">
                                        <div className="food-items-info">
                                            <span className="text-primary">FOOD</span>
                                            <h6>Original Big Burger with Extra Spicy </h6>
                                            <span>x2</span>
                                        </div>
                                        <div className="d-inline-flex text-nowrap">
                                            <span className="me-2">SAR 8,6</span>
                                            <h6 className="mb-0 text-primary">SAR 17,2</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="food-items-bx">
                                    <div className="food-items-media">
                                        <img src="/images/favirate-img/6.png" alt="" />
                                    </div>
                                    <div className="d-flex align-items-end">
                                        <div className="food-items-info">
                                            <span className="text-primary">FOOD</span>
                                            <h6>Original Big Burger with Extra Spicy </h6>
                                            <span>x2</span>
                                        </div>
                                        <div className="d-inline-flex text-nowrap">
                                            <span className="me-2">SAR 8,6</span>
                                            <h6 className="mb-0 text-primary">SAR 17,2</h6>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div className="food-totle">
                                    <ul className="d-flex align-items-center justify-content-between">
                                        <li><span>{t("Subtotal")}</span></li>
                                        <li><h6>SAR 25,8</h6></li>
                                    </ul>
                                    <ul className="d-flex align-items-center justify-content-between">
                                        <li><span>PPN (10%)</span></li>
                                        <li><h6>SAR 2,58</h6></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xl-3">
                <div className="row">
                    <div className="col-xl-12">
                        <div className="card bg-primary h-auto">
                            <div className="card-body text-center">
                                <h3 className="mb-0 text-white">{t("reservation")}{reservationDetails?.isWaiting ? t("waiting") : ""}<br />{reservationDetails?.dateSlot}<br /></h3>
                                <span className="text-white">{t("time")}: {reservationDetails?.timeSlot?.replace('AM', getLang() == "ar-SA" ? "ص" : "AM")?.replace('PM', getLang() == "ar-SA" ? "م" : "PM")}</span>
                                <br />
                                <span className="text-white">{t("for")}   {reservationDetails?.partySize} {t("people")}</span>


                            </div>
                        </div>
                    </div>
                    {/*      <div className="col-xl-12 col-sm-6">
                        <div className="card h-auto">
                            <div className="card-body">
                                <div className="profile-bx text-center">
                                    <img src="/images/coustomer-img/3.jpg" className="avatar avatar-lg mb-3" alt="" />
                                    <h4 className="title mb-0">Zeyad Ahmed</h4>
                                    <p>Customer</p>
                                         <ul className="d-inline-flex">
                                    <li><a href="javascript:void(0);" className="icon-bx icon-bx-md bg-primary-light"><svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M19.6872 15.8688L16.7331 13.8049C16.3468 13.5355 15.8952 13.3934 15.427 13.3934C14.6825 13.3934 13.9836 13.7586 13.5579 14.3695L12.8721 15.3523C11.7228 14.5815 10.4404 13.5052 9.21781 12.2829C7.99547 11.0605 6.91941 9.77809 6.14883 8.62878L7.13116 7.94302C7.63189 7.59435 7.9663 7.07202 8.07251 6.47265C8.17849 5.87373 8.0449 5.26857 7.69579 4.76762L5.63229 1.81352C5.2008 1.19634 4.50458 0.827637 3.76962 0.827637C3.51491 0.827637 3.26509 0.872611 3.02731 0.960335C2.75724 1.06008 2.50564 1.18254 2.25895 1.3375L1.85106 1.62472C1.74909 1.70398 1.65424 1.79081 1.5634 1.88165C1.06578 2.37905 0.71266 3.0087 0.513391 3.75323C-0.336904 6.94065 1.76957 11.7605 5.75496 15.7459C9.1018 19.0928 13.1226 21.1719 16.2479 21.1723C16.7831 21.1723 17.2879 21.11 17.7479 20.9871C18.4924 20.788 19.1221 20.4349 19.6199 19.9371C19.7103 19.8467 19.7967 19.7518 19.8893 19.632L20.1767 19.2219C20.3172 18.9975 20.4395 18.7459 20.5406 18.4738C20.8881 17.5343 20.5372 16.4629 19.6872 15.8688Z" fill="#1921FA" />
                                    </svg>
                                    </a></li>
                                    <li className="mx-3"><a href="javascript:void(0);" className="icon-bx icon-bx-md bg-primary-light"><svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M17.583 2.97363C15.6417 1.00896 12.9754 -0.0669374 10.2155 0.00322935C4.71908 0.166952 0.415505 4.7278 0.579228 10.2242C0.602617 11.3703 0.836506 12.4929 1.25751 13.5454C1.60834 14.4342 2.07612 15.2528 2.66085 16.0013L1.84223 17.9426C1.51479 18.691 1.86562 19.5564 2.61407 19.8839C2.87135 20.0008 3.1754 20.0242 3.45607 19.9774L7.24508 19.3225C8.60164 19.7903 10.0284 19.954 11.4551 19.8137C16.3434 19.3225 20.1558 15.323 20.4131 10.4113C20.5534 7.62803 19.5243 4.9383 17.583 2.97363ZM9.77109 13.8963H7.17492C6.77731 13.8963 6.42647 13.5688 6.42647 13.1478C6.42647 12.7502 6.75392 12.3994 7.17492 12.3994H9.77109C10.1687 12.3994 10.5195 12.7268 10.5195 13.1478C10.5195 13.5688 10.1921 13.8963 9.77109 13.8963ZM13.8174 10.8557H7.17492C6.77731 10.8557 6.42647 10.5283 6.42647 10.1073C6.42647 9.68626 6.75392 9.35882 7.17492 9.35882H13.8174C14.215 9.35882 14.5658 9.68626 14.5658 10.1073C14.5658 10.5283 14.215 10.8557 13.8174 10.8557ZM13.8174 7.79176H7.17492C6.77731 7.79176 6.42647 7.46431 6.42647 7.04331C6.42647 6.6457 6.75392 6.29486 7.17492 6.29486H13.8174C14.215 6.29486 14.5658 6.62231 14.5658 7.04331C14.5658 7.44092 14.215 7.79176 13.8174 7.79176Z" fill="#1921FA" />
                                    </svg>

                                    </a></li>
                                    <li><a href="javascript:void(0);" className="icon-bx icon-bx-md bg-primary-light"><svg width="21" height="24" viewBox="0 0 21 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M8.09961 7.5999V11.1999H9.69959V9.59988C9.69959 9.15805 10.0578 8.79989 10.4996 8.79989C10.9414 8.79989 11.2996 9.15805 11.2996 9.59988V11.1999H12.8995V7.5999L10.4996 5.79993L8.09961 7.5999Z" fill="#1921FA" />
                                        <path d="M10.4998 0C5.20042 0.00603926 0.905942 4.30051 0.899902 9.59987C0.899903 14.7338 9.56699 23.4009 9.9361 23.7677C10.2481 24.0774 10.7515 24.0774 11.0634 23.7677C11.4326 23.4009 20.0996 14.7338 20.0996 9.59987C20.0936 4.30051 15.7991 0.0060401 10.4998 0ZM14.4997 11.9998C14.4997 12.4417 14.1416 12.7998 13.6997 12.7998H7.29982C6.858 12.7998 6.49983 12.4417 6.49983 11.9998V7.19991C6.49962 6.94805 6.61821 6.71086 6.81983 6.55991L10.0198 4.15995C10.3043 3.94675 10.6953 3.94675 10.9798 4.15994L14.1797 6.55991C14.3813 6.71085 14.4999 6.94805 14.4997 7.19991L14.4997 11.9998Z" fill="#1921FA" />
                                    </svg>

                                    </a></li>
                                </ul> 
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="col-xl-12 col-sm-6">
                        <div className="card h-auto">
                            <div className="card-body">
                                <ul className="d-flex align-items-center mb-3" style={{
                                    flexDirection: 'column'
                                }}>
                                    <li>
                                        <img className="avatar avatar-md" src={reservationDetails?.applicationUser?.profileImage != "" ? baseURLPhotos + "/" + reservationDetails?.applicationUser?.profileImage : reservationDetails?.applicationUser?.gender == true ? "/woman.jpg" : "/man.jpg"} />


                                    </li>
                                    <li className="mt-2">
                                        <h6 className="mb-0">{reservationDetails?.applicationUser?.fullName}</h6>
                                    </li>
                                </ul>
                                <ul className="d-flex mb-3">
                                    <li>
                                        <a className="icon-bx icon-outline-bx "><svg width="21" height="22" viewBox="0 0 21 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.6872 15.8688L16.7331 13.8049C16.3468 13.5355 15.8952 13.3934 15.427 13.3934C14.6825 13.3934 13.9836 13.7586 13.5579 14.3695L12.8721 15.3523C11.7228 14.5815 10.4404 13.5052 9.21781 12.2829C7.99547 11.0605 6.91941 9.77809 6.14883 8.62878L7.13116 7.94302C7.63189 7.59435 7.9663 7.07202 8.07251 6.47265C8.17849 5.87373 8.0449 5.26857 7.69579 4.76762L5.63229 1.81352C5.2008 1.19634 4.50458 0.827637 3.76962 0.827637C3.51491 0.827637 3.26509 0.872611 3.02731 0.960335C2.75724 1.06008 2.50564 1.18254 2.25895 1.3375L1.85106 1.62472C1.74909 1.70398 1.65424 1.79081 1.5634 1.88165C1.06578 2.37905 0.71266 3.0087 0.513391 3.75323C-0.336904 6.94065 1.76957 11.7605 5.75496 15.7459C9.1018 19.0928 13.1226 21.1719 16.2479 21.1723C16.7831 21.1723 17.2879 21.11 17.7479 20.9871C18.4924 20.788 19.1221 20.4349 19.6199 19.9371C19.7103 19.8467 19.7967 19.7518 19.8893 19.632L20.1767 19.2219C20.3172 18.9975 20.4395 18.7459 20.5406 18.4738C20.8881 17.5343 20.5372 16.4629 19.6872 15.8688Z" fill="#1921FA" />
                                        </svg>
                                        </a>
                                    </li>
                                    <li className="ms-2">
                                        <span className="mb-0">{t("telephone")}</span>
                                        <h6 className="mb-0">{reservationDetails?.applicationUser?.mobile}</h6>
                                    </li>
                                </ul>
                                <ul className="d-flex mb-3">
                                    <li>
                                        <a className="icon-bx icon-outline-bx"><svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M17.583 2.97363C15.6417 1.00896 12.9754 -0.0669374 10.2155 0.00322935C4.71908 0.166952 0.415505 4.7278 0.579228 10.2242C0.602617 11.3703 0.836506 12.4929 1.25751 13.5454C1.60834 14.4342 2.07612 15.2528 2.66085 16.0013L1.84223 17.9426C1.51479 18.691 1.86562 19.5564 2.61407 19.8839C2.87135 20.0008 3.1754 20.0242 3.45607 19.9774L7.24508 19.3225C8.60164 19.7903 10.0284 19.954 11.4551 19.8137C16.3434 19.3225 20.1558 15.323 20.4131 10.4113C20.5534 7.62803 19.5243 4.9383 17.583 2.97363ZM9.77109 13.8963H7.17492C6.77731 13.8963 6.42647 13.5688 6.42647 13.1478C6.42647 12.7502 6.75392 12.3994 7.17492 12.3994H9.77109C10.1687 12.3994 10.5195 12.7268 10.5195 13.1478C10.5195 13.5688 10.1921 13.8963 9.77109 13.8963ZM13.8174 10.8557H7.17492C6.77731 10.8557 6.42647 10.5283 6.42647 10.1073C6.42647 9.68626 6.75392 9.35882 7.17492 9.35882H13.8174C14.215 9.35882 14.5658 9.68626 14.5658 10.1073C14.5658 10.5283 14.215 10.8557 13.8174 10.8557ZM13.8174 7.79176H7.17492C6.77731 7.79176 6.42647 7.46431 6.42647 7.04331C6.42647 6.6457 6.75392 6.29486 7.17492 6.29486H13.8174C14.215 6.29486 14.5658 6.62231 14.5658 7.04331C14.5658 7.44092 14.215 7.79176 13.8174 7.79176Z" fill="#1921FA" />
                                        </svg>

                                        </a>
                                    </li>
                                    <li className="ms-2">
                                        <span className="mb-0">{t("email")}</span>
                                        <h6 className="mb-0">{reservationDetails?.applicationUser?.email}	</h6>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div> : <Loader />}
    </>
}