export const AppTextArea = ({ label, placeholder, value, onChange, errorMessage, }: { errorMessage: string, label?: string, placeholder?: string, value: string, onChange: (val: any) => void }) => {
    return <div className="col-lg-12 mb-4">

        {label &&
            <h4 className="card-title">{label}</h4>
        }



        <textarea value={value} onChange={(event) => {
            onChange?.(event.target.value)
        }} className="form-txtarea mt-3 form-control" rows={8} ></textarea>
    </div>
}
