

export const AppCheckBox = ({ label, checked, onChange }: { label: string, placeholder?: string, checked: boolean, onChange: (val: any) => void }) => {
    return <div style={{ display: 'flex', flexDirection: 'row-reverse', justifyContent: 'flex-end' }}>
        <input checked={checked} onChange={onChange} className="form-check-input" type="checkbox" />
        <label style={{
            marginInlineStart: 0,
            marginInlineEnd: 10
        }} className="form-check-label">
            {label}
        </label>

    </div>
}