import moment from "moment";
import {parseISO } from 'date-fns'

import * as Yup from "yup";
import { getLang } from "./localizationHelper";
import ar from '../translations/ar/translation.json'
import en from '../translations/en/translation.json'
import { isoConverter } from ".";
export const formikFieldValidation = (touched: object, errors: object) => {
    return (fieldName: string) => {
        //@ts-ignore
        return touched[fieldName] && errors[fieldName] ? errors[fieldName] : false;
    };
};
export const setBackEndErrors = (errorsObject: object, setErrorFunction: (key: string, value: string) => void) => {
    for (let [key, value] of Object.entries(errorsObject ?? {})) {
        //value[0]
        setErrorFunction(key, value[0] );
    }
};


const numberPattern = Yup.string().matches(
    /^[0-9]*$/,
   getLang()=='ar'?'يلزم ادخال ارقام فقط': "This field Accepts Numbers only"
);

export const validationSchema = () => {
    return {
        login:{
            "email": Yup.string().required(getLang() == 'ar-SA' ?ar.email_required: en.email_required),
            "password": Yup.string().required(getLang() == 'ar-SA' ?ar.password_required: en.password_required)
        },
        addRestaurant:{
            "title": Yup.string().required(getLang() == 'ar-SA' ?ar.restaurant_name_required: en.restaurant_name_required),
            "countryId": Yup.string().required(getLang() == 'ar-SA' ? ar.country_required :en.country_required)
        },
        editRestaurant: {
          //  "id": "3fa85f64-5717-4562-b3fc-2c963f66afa6",
          "title": Yup.string().required(getLang() == 'ar-SA' ?ar.restaurant_name_required: en.restaurant_name_required),
          "slug":Yup.string(),
            "isLogoChanged": Yup.boolean(),
            "logoImageFile": Yup.string(),
            "isBannerChanged": Yup.boolean(),
            "bannerImageFile": Yup.string(),
            "primaryColor": Yup.string(),
            "secondaryColor":Yup.string(),
            "welcomeMsg": Yup.string(),
            "menuUrl": Yup.string(),
            "countryId": Yup.string().required(getLang() == 'ar-SA' ? ar.country_required :en.country_required)
        },
        
        addSubscription:{
            "numberOfBranches":numberPattern.required(getLang() == 'ar-SA' ?ar.numberOfBranches_required : en.numberOfBranches_required),
            "numberOfUsers": numberPattern.required(getLang() == 'ar-SA' ? ar.numberOfUsers_required : en.numberOfUsers_required),
            "numberOfMonths":numberPattern.required(getLang() == 'ar-SA' ? ar.numberOfMonths_required :en.numberOfMonths_required),
            "amount": numberPattern.required(getLang() == 'ar-SA' ? ar.amount_required :en.amount_required ),
        },
        addSurvey: {
         
            "title":Yup.string().required(getLang() == 'ar-SA' ? ar.survey_name_required :en.survey_name_required),
            
            "surveyRateStyleId": Yup.string().required(getLang() == 'ar-SA' ? ar.survey_style_required :en.survey_style_required)
        },
        editSurvey: {
            "title":Yup.string().required(getLang() == 'ar-SA' ? ar.survey_name_required :en.survey_name_required),
            
            "surveyRateStyleId": Yup.string().required(getLang() == 'ar-SA' ? ar.survey_style_required :en.survey_style_required),
            "description": Yup.string(),
            "enableNotes": Yup.boolean(),
            "isActive": Yup.boolean(),
        },
        addSurveyQues:{
            "text":  Yup.string().required(getLang() == 'ar-SA' ? ar.text_required :en.text_required),
            "enableNotes": false,
            "isActive": false,
        },
        addBranch:{
            "title": Yup.string().required(getLang() == 'ar-SA' ?ar.branch_name_required: en.branch_name_required),
            "cityId": Yup.string().required(getLang() == 'ar-SA' ? ar.city_required :en.city_required)
        },
        editBranch: {
         
                "title": Yup.string().required(getLang() == 'ar-SA' ?ar.branch_name_required: en.branch_name_required),
            "cityId": Yup.string().required(getLang() == 'ar-SA' ? ar.city_required : en.city_required),
            "slug": Yup.string(),
            "location": Yup.string(),            
        },
        addWorkingSystem:{
            "title": Yup.string().required(getLang() == 'ar-SA' ?ar.title_required: en.title_required),
            "workingSystemTypeId": Yup.string().required(getLang() == 'ar-SA' ? ar.workingSystemType_required :en.workingSystemType_required)
        },
        editWorkingSystem: {
            "title": Yup.string().required(getLang() == 'ar-SA' ?ar.title_required: en.title_required),
            "workingSystemTypeId": Yup.string().required(getLang() == 'ar-SA' ? ar.workingSystemType_required : en.workingSystemType_required),
            "startDate": Yup.string().nullable(),
            "endDate":Yup.string().nullable(),
            "maxFutureDays":Yup.string(),
        },
        addWorkingSystemDay:{
            "weekDayId":Yup.string().required(getLang() == 'ar-SA' ?ar.weekDay_required: en.weekDay_required),  
            "openTime": Yup.string().required(getLang() == 'ar-SA' ?ar.openTime_required: en.openTime_required),
            "closeTime": Yup.string().required(getLang() == 'ar-SA' ?ar.closeTime_required: en.closeTime_required),
               
        },
        addTenantManager: {
            "firstName": Yup.string().required(getLang()=="ar-SA"?ar.firstName_required:en.firstName_required),
            "lastName": Yup.string().required(getLang()=="ar-SA"?ar.lastName_required:en.lastName_required),
            "mobile":Yup.string().required(getLang()=="ar-SA"?ar.mobile_required:en.mobile_required),
            "email": Yup.string().required(getLang()=="ar-SA"?ar.email_required:en.email_required),
    
        },
        addBranchManager:{
            "firstName": Yup.string().required(getLang()=="ar-SA"?ar.firstName_required:en.firstName_required),
            "lastName": Yup.string().required(getLang()=="ar-SA"?ar.lastName_required:en.lastName_required),
            "mobile":Yup.string().required(getLang()=="ar-SA"?ar.mobile_required:en.mobile_required),
            "email": Yup.string().required(getLang()=="ar-SA"?ar.email_required:en.email_required),
    
        },
        changePassword:{
            "currentPassword": Yup.string().required(getLang()=="ar-SA"?ar.currentPasswordRequired:en.currentPasswordRequired),
            "newPassword": Yup.string().required(getLang()=="ar-SA"?ar.newPasswordRequired:en.newPasswordRequired),
            "confirmPassword":Yup.string().required(getLang()=="ar-SA"?ar.confirmPasswordRequired:en.confirmPasswordRequired)
            .oneOf([Yup.ref('newPassword')],getLang()=="ar-SA"?ar.confirmPasswordMatch:en.confirmPasswordMatch)
       ,
        },
        updateProfile: {
          
            "mobile": Yup.string().required(getLang() == "ar-SA" ? ar.mobile_required : en.mobile_required),
           

        },
        
}

};

export const initialValues = (dataSource: any) => {
    return {
        login:{email:'',password:''},
        addRestaurant:{
            "title": dataSource?.title??null,
            "countryId": dataSource?.countryId??null
        },
        editRestaurant: {
            "title": dataSource?.title??'',
            "slug":dataSource?.slug??'',
            "logoImage": dataSource?.logoImage??'',
            "bannerImage": dataSource?.bannerImage??'',
            "primaryColor": dataSource?.primaryColor??'transparent',
            "secondaryColor":dataSource?.secondaryColor??'transparent',
            "welcomeMsg": dataSource?.welcomeMsg??'',
            "menuUrl": dataSource?.menuUrl??'',
            "countryId": dataSource?.countryId??''
        },
        
        addSubscription:{
            "numberOfBranches": dataSource?.numberOfBranches??null,
            "numberOfUsers": dataSource?.numberOfUsers??null,
            "numberOfMonths": dataSource?.numberOfMonths??null,
            "amount": dataSource?.amount??null,
        },
        addSurvey: {
         
            "title":dataSource?.title??null,
            
            "surveyRateStyleId": dataSource?.surveyRateStyleId??null
        },
        editSurvey: {
            "title":dataSource?.title??null,
            "surveyRateStyleId": dataSource?.surveyRateStyleId ?? null,
            "description": dataSource?.description ?? "",
            "enableNotes": dataSource?.enableNotes??false,
            "isActive": dataSource?.isActive??false,
        },
        addSurveyQues:{
            "text": dataSource?.text??null,
            "enableNotes":dataSource?.enableNotes?? false,
            "isActive":dataSource?.isActive?? false,
        },
        addBranch: {
            "title": dataSource?.title ?? null,
            "cityId":dataSource?.cityId??null
        },
        editBranch: {
         
            "title": dataSource?.title ?? null,
            "cityId":dataSource?.cityId??null,
            "slug": dataSource?.slug??null,
            "location":dataSource?.location??null,
            
        },
        addWorkingSystem:{
            "title":  dataSource?.title ?? null,
            "workingSystemTypeId":dataSource?.workingSystemTypeId??null
        },
        addWorkingSystemDay:{
            "weekDayId":dataSource?.weekDayId??"",
            "timeIncrement": dataSource?.timeIncrement??0,
            "maxGuestsNumberPerReservation": dataSource?.maxGuestsNumberPerReservation??0,
          
          //Default
            "openTime": dataSource?.openTime ?parseISO(dataSource?.openTime ):null,
            "closeTime": dataSource?.closeTime ?parseISO(dataSource?.closeTime):null,
            "maxReservationsNumber": dataSource?.maxReservationsNumber??0,
            "maxGuestsNumber": dataSource?.maxGuestsNumber??0,
            "downPaymentPerReservation": dataSource?.downPaymentPerReservation??0,
            "downPaymentPerGuest": dataSource?.downPaymentPerGuest??0,
          
          //Waiting
            "allowWaitingList": dataSource?.allowWaitingList??false,
            "waitingOpenTime": dataSource?.waitingOpenTime ?parseISO(dataSource?.waitingOpenTime):null,
            "waitingCloseTime":dataSource?.waitingCloseTime ?
            parseISO(dataSource?.waitingCloseTime):null
            ,
            "maxWaitingReservationsNumber": dataSource?.maxWaitingReservationsNumber??0,
            "maxWaitingGuestsNumber": dataSource?.maxWaitingGuestsNumber??0,
          
        },
 
        editWorkingSystem:{
            "title":  dataSource?.title ?? null,
            "workingSystemTypeId": dataSource?.workingSystemTypeId ?? null,
            "startDate":dataSource?.startDate?isoConverter(dataSource?.startDate):null,
            "endDate":dataSource?.endDate?isoConverter(dataSource?.endDate) :null,
            "maxFutureDays": dataSource?.maxFutureDays ?? null,
        },
        addBranchManager:{
            "firstName":  dataSource?.firstName ?? null,
            "lastName": dataSource?.lastName ?? null,
            "email":dataSource?.email??null,
            "mobile":dataSource?.mobile??null,
        },
        editBranchManager:{
            "firstName":  dataSource?.firstName ?? null,
            "lastName": dataSource?.lastName ?? null,
            "email":dataSource?.email??null,
            "mobile": dataSource?.mobile ?? null,
            
        },
        assignBranchManager:{
            "branchId":  dataSource?.branchId ?? null,
            
        },
        addTenantManager:{
            "firstName":  dataSource?.firstName ?? null,
            "lastName": dataSource?.lastName ?? null,
            "email":dataSource?.email??null,
            "mobile":dataSource?.mobile??null,
        },
        editTenantManager:{
            "firstName":  dataSource?.firstName ?? null,
            "lastName": dataSource?.lastName ?? null,
            "email":dataSource?.email??null,
            "mobile": dataSource?.mobile ?? null,
            
        },
        assignTenantManager:{
            "tenantId":  dataSource?.tenantId ?? null,
            
        },
        changePassword: {
            "currentPassword": "",
            "newPassword": "",
            "confirmPassword": "",

        },
        updateProfile: {
            "firstName": dataSource?.firstName??null,
            "lastName":dataSource?.lastName?? null,
            "email":dataSource?.email?? null,
            "mobile":dataSource?.mobile??null,
            "gender":dataSource?.gender?? false,
            "dateOfBirth": dataSource?.dateOfBirth?parseISO(dataSource?.dateOfBirth):null,
            "profileImage":dataSource?.profileImage??null,

        },

    }
};

export const formikSetup = (
    initialValues: (data: object) => object,
    sourceOfIntailData: object | undefined, //could be undefined
    validationSchema: () => object,
    pageName: string,
    submitFunction: (values?: any) => void
) => {
    return {
        enableReinitialize: true,
        initialValues: {
            //@ts-ignore
            ...initialValues(sourceOfIntailData)[pageName],
        },
        //@ts-ignore
        validationSchema: Yup.object({ ...validationSchema()[pageName] }),
        onSubmit: submitFunction,
    };
};
