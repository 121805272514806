import { useTranslation } from "react-i18next"
import { AppInput } from "../components/formsComponents/input"
import { LanguageDropDown } from "../components/layout/components/header/languageDropDown"
import { getLang } from "../helpers/localizationHelper"
import { AppCheckBox } from "../components/formsComponents/checkbox"
import { useEffect, useState } from "react"

import { useDispatch, useSelector } from "react-redux"
import { loginAPI } from "../apis/auth"
import { useFormik } from "formik"
import { formikFieldValidation, formikSetup, initialValues, validationSchema } from "../helpers/formik"
import { useLocation, useNavigate, useParams } from "react-router"
import { Loader } from "../components/loader"
import Cookies from "universal-cookie";
import moment from "moment"
import { useSearchParams } from "react-router-dom"

export const LoginScreen = () => {
    const { t } = useTranslation()
    const dispatch = useDispatch<any>()
    const [loading, setLoading] = useState(false)
    const [rememberMe, setRememberMe] = useState(false)
    const cookies = new Cookies();
    const navigate = useNavigate()
    const location = useLocation()

    const [searchParams, setSearchParams] = useSearchParams();
    const returnURL = searchParams.get("returnURL")

    const submit = async (values: any) => {
        setLoading(true)
        try {
            const res = await (loginAPI({ ...values, rememberMe: rememberMe }))
            if (res?.status) {
                cookies.set("token", `Bearer ${res?.token}`, { expires: moment(res?.time).toDate(), path: "/" })
                if (returnURL != null) {
                    navigate(`${returnURL}`)
                } else {
                    navigate(`/admin`)
                }
                window.location.reload()
            }
        } catch (e: any) {
            //setBackEndErrors(e?.response?.data?.errors, setFieldError)
        }
        setLoading(false)
    };

    const formik = useFormik(
        formikSetup(initialValues, undefined, validationSchema, 'login', submit),
    );
    const { handleChange, handleSubmit, values, touched, errors, setFieldValue, setFieldError } = formik;
    const isValid = formikFieldValidation(touched, errors);
    /*     useEffect(() => {
            setRememberMe(localStorage.getItem('remember') == "true" ? true : false)
            //@ts-ignore
            localStorage.setItem('remember', rememberMe)
        }, []) */

    useEffect(() => {
        const keyDownHandler = (event: any) => {
            if (event.key === 'Enter') {
                // 👇️ your logic here
                handleSubmit();
            }
        };

        document.addEventListener('keydown', keyDownHandler);

        return () => {
            document.removeEventListener('keydown', keyDownHandler);
        };
    }, []);

    return <div className="vh-100 position-relative" style={{ backgroundImage: "url('/images/background/bg6.jpg')", backgroundPosition: "center" }}>

        <div className="authincation h-100">
            <div className="container h-100">
                <div className="row justify-content-center h-100 align-items-center">
                    <div style={{ position: 'relative' }} className="col-md-6">
                        <div style={{ position: 'absolute', top: 10, right: getLang() == 'ar-SA' ? 30 : undefined, left: getLang() == 'en-US' ? 30 : undefined }}>

                            <ul className="navbar-nav">
                                <LanguageDropDown />
                            </ul>
                        </div>
                        <div className="authincation-content">
                            <div className="row no-gutters">
                                <div className="col-xl-12">
                                    <div className="auth-form">
                                        <div className="text-center mb-3">
                                            <a ><img src="/images/logo/logo-full.png" alt="" /></a>
                                        </div>
                                        <h4 className="text-center mb-4">{t('Login to your account')}</h4>

                                        <div className="mb-3">
                                            <AppInput className="col-xl-12 col-lg-12" value={values?.email} onChange={handleChange('email')} label={t("email")} errorMessage={isValid('email')} />
                                        </div>
                                        <div className="mb-3">
                                            <AppInput isPassword={true} type="password" className="col-xl-12 col-lg-12" value={values?.password} onChange={handleChange('password')} label={t("password")} errorMessage={isValid('password')} />
                                        </div>
                                        <div className="mb-3">
                                            <AppCheckBox checked={rememberMe} onChange={(e) => {
                                                setRememberMe(e.target.checked);
                                                localStorage.setItem('remember', e.target.checked)
                                            }} label={t("Remember me")} />
                                        </div>

                                        <div className="text-center mt-4">

                                            <button
                                                //@ts-ignore
                                                onClick={handleSubmit} className="btn btn-primary btn-block">{t('Sign in')}</button>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        {loading && <Loader />}
    </div>
}