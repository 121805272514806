import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next"
import Cookies from "universal-cookie";
import { resolveRequest } from "../../../../helpers";
import { updatePassword, userDataApi } from "../../../../apis/auth";
import { UserAllData } from "../../../../apis/UserProfile";
import { useQuery } from "react-query";
import { useNavigate } from "react-router";
import { AppModal } from "../../../modal";
import { AppInput } from "../../../formsComponents/input";
import { useFormik } from "formik";
import { formikFieldValidation, formikSetup, initialValues, validationSchema } from "../../../../helpers/formik";
import { baseURLPhotos } from "../../../../apis/endpoints";

export const ProfileDropdown = () => {
    const { t } = useTranslation()
    const cookies = new Cookies();
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)

    const [changePasswordModal, setChangePasswordModal] = useState(false)
    const [profileModal, setProfileModal] = useState(false)

    const submit = async (values: any) => {
        try {
            setLoading(true)
            const res = await updatePassword({ data: values })
            if (res?.status) {
                resetForm({ values: {} })
                setChangePasswordModal(false)
            }
            setLoading(false)

        } catch (e: any) {
            setLoading(false)

            //setBackEndErrors(e?.response?.data?.errors, setFieldError)
        }
    };
    const formik2 = useFormik(
        formikSetup(initialValues, undefined, validationSchema, 'changePassword', submit),
    );
    const { handleChange, handleSubmit, values, touched, errors, resetForm } = formik2;
    const isValid = formikFieldValidation(touched, errors);

    const fetchUserData = async () => {
        /*         return await UserMenu()
         */
        return await UserAllData()
    }
    const { isLoading, isError, error, data } = useQuery(['userAllData'], fetchUserData, {
        refetchInterval: 60000
    })
    /*     const [userData, setUserData] = useState<any>()
        useEffect(() => {
            resolveRequest(userDataApi, setUserData)
        }, []) */
    return <>
        <AppModal
            className="modal2"

            show={changePasswordModal}
            onClose={() => {
                setChangePasswordModal(false)
            }}
            body={
                <>

                    <div className="row">
                        <AppInput isPassword value={values?.currentPassword} onChange={handleChange('currentPassword')} label={t("currentPassword")} errorMessage={isValid('currentPassword')} />
                        <AppInput isPassword value={values?.newPassword} onChange={handleChange('newPassword')} label={t("newPassword")} errorMessage={isValid('newPassword')} />
                        <AppInput isPassword value={values?.confirmPassword} onChange={handleChange('confirmPassword')} label={t("confirmPassword")} errorMessage={isValid('confirmPassword')} />

                    </div>
                </>
            } title={t('changePassword')} onSaveClick={handleSubmit} />

        <li className="nav-item ps-3">
            <div className="dropdown header-profile2">
                <a className="nav-link" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    <div className="header-info2 d-flex align-items-center">
                        <div className="header-media">
                            <img width={50} height={50} src={(data?.data?.user?.profileImage != "" && data?.data?.user?.profileImage != undefined && data?.data?.user?.profileImage != null) ? (baseURLPhotos + "/" + data?.data?.user?.profileImage) : data?.data?.user?.gender == true ? "/woman.jpg" : "/man.jpg"} />
                        </div>
                    </div>
                </a>
                <div className="dropdown-menu dropdown-menu-end" >
                    <div className="card border-0 mb-0">
                        <div className="card-header py-2">
                            <div className="products">
                                <img style={{ borderRadius: '50%' }} width={50} height={50} className="avatar avatar-md" src={(data?.data?.user?.profileImage != "" && data?.data?.user?.profileImage != undefined && data?.data?.user?.profileImage != null) ? baseURLPhotos + "/" + data?.data?.user?.profileImage : data?.data?.user?.gender == true ? "/woman.jpg" : "/man.jpg"} />
                                <div>
                                    <h6>{data?.data?.user?.fullName}</h6>
                                    <span>{data?.data?.user?.email}</span>
                                </div>
                            </div>
                        </div>
                        <div className="card-body px-0 py-0">
                            <a href="/admin/profile" className="dropdown-item ai-icon p-1 px-2">
                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9848 15.3462C8.11714 15.3462 4.81429 15.931 4.81429 18.2729C4.81429 20.6148 8.09619 21.2205 11.9848 21.2205C15.8524 21.2205 19.1543 20.6348 19.1543 18.2938C19.1543 15.9529 15.8733 15.3462 11.9848 15.3462Z" stroke="var(--primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9848 12.0059C14.5229 12.0059 16.58 9.94779 16.58 7.40969C16.58 4.8716 14.5229 2.81445 11.9848 2.81445C9.44667 2.81445 7.38857 4.8716 7.38857 7.40969C7.38 9.93922 9.42381 11.9973 11.9524 12.0059H11.9848Z" stroke="var(--primary)" stroke-width="1.42857" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                                <span className="ms-2">{t("profile")} </span>
                            </a>
                        </div>
                        <div className="card-body px-0 py-0">
                            <a style={{ cursor: 'pointer' }} onClick={() => {
                                setChangePasswordModal(true)
                            }} className="dropdown-item ai-icon p-1 px-2">
                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9848 15.3462C8.11714 15.3462 4.81429 15.931 4.81429 18.2729C4.81429 20.6148 8.09619 21.2205 11.9848 21.2205C15.8524 21.2205 19.1543 20.6348 19.1543 18.2938C19.1543 15.9529 15.8733 15.3462 11.9848 15.3462Z" stroke="var(--primary)" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M11.9848 12.0059C14.5229 12.0059 16.58 9.94779 16.58 7.40969C16.58 4.8716 14.5229 2.81445 11.9848 2.81445C9.44667 2.81445 7.38857 4.8716 7.38857 7.40969C7.38 9.93922 9.42381 11.9973 11.9524 12.0059H11.9848Z" stroke="var(--primary)" stroke-width="1.42857" stroke-linecap="round" stroke-linejoin="round" />
                                </svg>

                                <span className="ms-2">{t("changePassword")} </span>
                            </a>
                        </div>

                        <div className="card-footer px-0 py-2">

                            <a onClick={() => {
                                cookies.remove("token", { path: '/' })
                                navigate(`/login`)

                                // window.location.reload()
                            }} className="dropdown-item ai-icon">
                                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 24 24" fill="none" stroke="var(--primary)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path><polyline points="16 17 21 12 16 7"></polyline><line x1="21" y1="12" x2="9" y2="12"></line></svg>
                                <span className="ms-2">{t('logout')} </span>
                            </a>
                        </div>
                    </div>

                </div>
            </div>
        </li></>
}