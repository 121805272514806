export const baseURL = process.env.REACT_APP_BASEURL;
export const baseURLPhotos = process.env.REACT_APP_BASEURL_PHOTOS;

export const endpoints = {
  restaurants: {
    addRestaurant: "/Tenant/AddTenant",
    listing: "/Tenant/GetTenantList",
    active: "/Tenant/ChangeActiveTenant",
    publish: "/Tenant/ChangePublishTenant",
    delete: "/Tenant/DeleteTenant",
    edit: "/Tenant/EditTenant",
    details: "/Tenant/GetTenantById",
    lookup: "/Tenant/GetAllTenant",
  },
  subscriptions: {
    listing: "/TenantSubscription/GetTenantSubscriptionsByTenantId",
    addSubscription: "/TenantSubscription/AddOrEditTenantSubscription",
    deleteSubscription: "/TenantSubscription/DeleteTenantSubscription",
    details: "/TenantSubscription/GetTenantSubscriptionById",
  },
  lookups: {
    countries: "/Lookup/GetAllCountries",
    surveysStyles: "/Lookup/GetAllSurveyRateStyles",

    languages: "/Lookup/GetAllLanguages",
    tenantCities: "/Lookup/GetAllCitiesForUserTenant",
    workingSystems: "/Lookup/GetAllWorkingSystemTypes",
  },
  auth: {
    login: "/Auth/Login",
    userData: "/UserProfile/GetUserProfileData",
    changePassword: "/UserProfile/ChangePassword",
    updateProfile: "/UserProfile/UpdateUserProfileData",
    getUserData: "/UserProfile/GetUserProfileData",
  },
  surveys: {
    listing: "/Survey/GetSurveyList",
    addEdit: "/Survey/AddOrEditSurvey",
    delete: "/Survey/DeleteSurvey",
    details: "/Survey/GetSurveyById",
  },
  surveyQues: {
    details: "/SurveyQuestion/GetSurveyQuestionById",
    listing: "/SurveyQuestion/GetSurveyQuestions",
    delete: "/SurveyQuestion/DeleteSurveyQuestion",
    add: "/SurveyQuestion/AddOrEditSurveyQuestion",
  },
  branches: {
    listing: "/Branch/GetUserTenantBranches",
    details: "/Branch/GetBranchById",
    addEdit: "/Branch/AddOrEditBranch",
    active: "/Branch/ChangeActiveBranch",
    delete: "/Branch/DeleteBranch",
  },
  workingSystem: {
    listing: "/WorkingSystem/GetBranchWorkingSystemList",
    details: "/WorkingSystem/GetBranchWorkingSystemById",
    add: "/WorkingSystem/AddWorkingSystem",
    edit: "/WorkingSystem/EditWorkingSystem",
    active: "/WorkingSystem/ChangeActiveWorkingSystem",
    default: "/WorkingSystem/SetDefaultWorkingSystem",
    delete: "/WorkingSystem/DeleteWorkingSystem",
  },
  workingSystemDays: {
    listing: "/WorkingSystemDay/GetWorkingSystemDays",
    details: "/WorkingSystemDay/GetWorkingSystemDayById",
    addEdit: "/WorkingSystemDay/AddOrEditWorkingSystemDay",
    delete: "/WorkingSystemDay/DeleteWorkingSystemDay",
    lookupWeekAdd: "/WorkingSystemDay/GetAvailableWorkingSystemDays",
    lookupWeekEdit: "/WorkingSystemDay/GetAvailableWorkingSystemDaysEdit",
  },
  reservations: {
    listing: "/Reservation/GetReservationList",
    details: "/Reservation/GetReservationById",
  },
  branchManagers: {
    listing: "/BranchManager/GetBranchManagersList",
    details: "/BranchManager/GetBranchManagerById",
    add: "/BranchManager/AddBranchManager",
    edit: "/BranchManager/EditBranchManager",
    assign: "/BranchManager/AssignBranchManager",
    branches: "/Branch/GetUserTenantBranches",
  },
  tenantManagers: {
    listing: "/TenantManager/GetTenantManagersList",
    details: "/TenantManager/GetTenantManagerById",
    add: "/TenantManager/AddTenantManager",
    edit: "/TenantManager/EditTenantManager",
    assign: "/TenantManager/AssignTenantManager",
    managerRestaurantDetails: "/tenant/GetUserTenant",
    managerRestaurantUpdate: "/tenant/EditUserTenant",
  },
  UserProfile: {
    UserMenu: "/UserProfile/GetUserMenu",
    userAdminData: "/UserProfile/GetAdminUserData",
  },
  adminDashboard: {
    numbers: "/Dashboard/AdminCardNumbers",
    reservations: "/Dashboard/AdminReservationsPerMonth",
    reviews: "/Dashboard/AdminReviewsResult",
  },
  tenantManagerDashboard: {
    numbers: "/Dashboard/TenantManagerCardNumbers",
    reservations: "/Dashboard/TenantManagerReservationsPerMonth",
    reviews: "/Dashboard/TenantManagerReviewsResult",
  },
  branchManagerDashboard: {
    numbers: "/Dashboard/BranchManagerCardNumbers",
    reservations: "/Dashboard/BranchManagerReservationsPerMonth",
    reviews: "/Dashboard/BranchManagerReviewsResult",
  },
};
