import { getLang } from "../../helpers/localizationHelper"
import DatePicker from "react-datepicker";

export const AppDateTimepicker = ({ label, time = false, value, onChange, placeholder, errorMessage, require = false, }: { require?: boolean, errorMessage?: string, time?: boolean, label?: string, placeholder?: string, value: any, onChange: (val: any) => void }) => {
    return <div className="col-xl-6 col-lg-6 col-12 mb-3">



        {label &&
            <h4 className="card-title">
                {require ? <span style={{ color: 'red' }}> * </span> : null}{" "}
                {label}</h4>
        }

        <DatePicker

            className="form-control"
            wrapperClassName=" mt-3 col-12"
            selected={value}
            dateFormat={"yyyy-MM-dd"}
            onChange={onChange}
        />
        {/*  <input type={time == false ? "date" : "time"} placeholder={placeholder} value={value} onChange={(event) => {
            onChange?.(event.target.value)
        }} className="form-control mt-3" /> */}
        {errorMessage && (
            <p style={{ marginTop: 5, fontSize: 12, marginBottom: 5, color: "red" }}>
                {errorMessage}
            </p>
        )}

    </div>
}