import { useTranslation } from "react-i18next"
import { Modal } from "react-bootstrap"

export const AppModal = ({ className, saveBtnLabel, closeBtnLabel, onSaveClick, title, body, show, onClose }: { className?: string, closeBtnLabel?: string, show: boolean, body: any, saveBtnLabel?: string, onSaveClick: () => void, title?: string, onClose?: any }) => {
    const { t } = useTranslation()
    return <><Modal
        dialogClassName={className}
        show={show} onHide={onClose} >

        <div className="p-3 d-flex justify-content-between">
            <h5 className="modal-title">{title}</h5>
            <button type="button" className="btn-close" onClick={onClose}>
            </button>
        </div>
        {body && <div style={{ fontSize: 20 }} className="modal-body">{body}</div>}
        <div className="modal-footer justify-content-center">
            <button onClick={onSaveClick} type="submit" className="btn btn-primary">{saveBtnLabel ?? t('save')}</button>
            <button onClick={onClose} type="button" className="btn btn-danger light" data-bs-dismiss="modal">{closeBtnLabel ?? t("close")}</button>
        </div>
    </Modal>
    </>

}