import { useEffect, useState } from "react"
import { CrossedEyeIcon } from "../../styles/svg/crossedEyeIcon"
import { getLang } from "../../helpers/localizationHelper"
import { EyeIcon } from "../../styles/svg/eyeIcon"
import { useTranslation } from "react-i18next"
import { resolveRequest } from "../../helpers"
import { GetAllCountries } from "../../apis/lookups"

export const AppMobileNum = ({ country, setCountry, disable = false, className, label, placeholder, value, onChange, require = false, errorMessage, type = "text", isPassword = false }: { setCountry: any, country: any, isPassword?: boolean, disable?: boolean, className?: string, type?: string, errorMessage: string, require?: boolean, label?: string, placeholder?: string, value: string, onChange: (val: any) => void }) => {
    const [allCountries, setAllCountries] = useState<any>()
    useEffect(() => {
        resolveRequest(GetAllCountries, setAllCountries)

    }, [getLang()])
    const { t } = useTranslation()
    return <div style={{ position: 'relative' }} className={`col-xl-6 col-lg-6 mb-4 ${className}`}>

        {label &&
            <h4 className="card-title">
                {require ? <span style={{ color: 'red' }}> * </span> : null}{" "}
                {label}</h4>
        }

        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', marginTop: 0 }}>
            <select style={{ width: '25%', marginInlineEnd: 10 }} defaultValue={undefined} value={country} onChange={(e) => {
                setCountry(e.target.value)
            }} className="form-control">{/* default-select  */}
                <option disabled selected value="">{t("country")}</option>
                {allCountries && allCountries?.map((item: any) => {
                    return <option key={item?.dialCode} value={item?.dialCode}>{item.fullCode}</option>
                })

                }
            </select>
            <input style={{ width: '75%' }} readOnly={country === undefined || country === null} value={value} onChange={(event) => {
                onChange?.(event?.target?.value)
            }} type={"text"} className="form-control input-default" />


        </div>
        {errorMessage && (
            <p style={{ marginTop: 5, fontSize: 12, marginBottom: 5, color: "red" }}>
                {errorMessage}
            </p>
        )}

    </div>
}