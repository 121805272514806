import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { useNavigate, useParams } from "react-router"
import { getLang } from "../../helpers/localizationHelper"
import { resolveRequest } from "../../helpers"
import { GetAllTenantCities } from "../../apis/lookups"
import { formikFieldValidation, formikSetup, initialValues, setBackEndErrors, validationSchema } from "../../helpers/formik"
import { useFormik } from "formik"
import { AppButton } from "../../components/button"
import { AppInput } from "../../components/formsComponents/input"
import { AppDropdownlist } from "../../components/formsComponents/dropdownlist"
import { Loader } from "../../components/loader"
import { BranchDetails, addBranch } from "../../apis/branches"

export const EditBranchScreen = () => {
    const { id } = useParams()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const [requestDone, setRequestDone] = useState(false)
    const [isLoading, setLoading] = useState(false)
    const [allCities, setAllCities] = useState()
    const [branchDetails, setBranchDetails] = useState()
    useEffect(() => {
        resolveRequest(BranchDetails, setBranchDetails, { id: id })
    }, [id, getLang(), requestDone])
    useEffect(() => {
        resolveRequest(GetAllTenantCities, setAllCities)

    }, [getLang()])
    const submit = async (values: any) => {

        setLoading(true)
        try {
            const res = await addBranch({ ...values, id: id, })


        } catch (e: any) {
            setBackEndErrors(e?.response?.data?.errors, setFieldError)

        }
        setLoading(false)
    };

    const formik = useFormik(
        formikSetup(initialValues, branchDetails, validationSchema, 'editBranch', submit),
    );
    const { handleChange, handleSubmit, values, touched, errors, setFieldValue, setFieldError } = formik;

    const isValid = formikFieldValidation(touched, errors);

    return <div className="pe-5 ps-5">


        <div className="card p-3 mt-4 pb-1 pt-1">
            <div className="d-flex justify-content-between align-items-center">
                <p className="commonHeader">
                    {t('edit_branch')}
                </p>
                <AppButton title=">" onClick={() => {
                    navigate('/admin/branches')
                }} />

            </div>
        </div>

        <div style={{ marginTop: -15 }} className="card p-3 pb-5 pt-2">


            <div className="row">
                <AppInput label={t('branch_name')} require onChange={handleChange('title')} errorMessage={isValid('title')} value={values?.title} />
                <AppDropdownlist errorMessage={isValid('cityId')} require label={t('city')} onChange={(val) => {
                    setFieldValue('cityId', val?.target?.value)
                }} items={allCities} value={values?.cityId} />
            </div>
            <div className="row">
                <AppInput label={t('location')} onChange={handleChange('location')} errorMessage={isValid('location')} value={values?.location} />
                <AppInput label={t('slug')} onChange={handleChange('slug')} errorMessage={isValid('slug')} value={values?.slug} />
            </div>

            <div className="d-flex justify-content-end mt-5">
                <AppButton title={t("save")} onClick={handleSubmit} />
            </div>
        </div>

        {isLoading && <Loader />}

    </div>
}