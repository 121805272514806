import { endpoints } from "../endpoints";
import axiosCall from "../axios/axiosConfig";

export const addWorkingSystem = (data) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.workingSystem.add, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else reject(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const editWorkingSystem = (data) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.workingSystem.edit, data)
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else reject(res);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const activeWorkingSystem = ({ id, flag }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.workingSystem.active, { id: id, flag: flag })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else
          reject({
            status: false,
            message: res?.data?.message,
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const deleteWorkingSystem = ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.workingSystem.delete, {
        id: id,
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
          });
        } else
          reject({
            status: false,
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const listingWorkingSystem = ({ pageNumber = 1, pageSize = 10 }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.workingSystem.listing, {
        pageNumber: pageNumber,
        pageSize: pageSize,
        searchText: "str",
        orderBy: "str",
        orderDirection: "str",
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            data: res?.data?.data || res?.data?.obj,
            totalRows: res?.data?.totalRowsCount,
          });
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const WorkingSystemDetails = ({ id }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.workingSystem.details, {
        id: id,
      })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve(res?.data?.data || res?.data?.obj);
        } else reject(res?.data?.message);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const defaultWorkingSystem = ({ id, flag }) => {
  return new Promise((resolve, reject) => {
    axiosCall
      .post(endpoints.workingSystem.default, { id: id, flag: flag })
      .then((res) => {
        if (res?.data?.code == 200) {
          resolve({
            status: true,
            message: res?.data?.message,
            data: res?.data?.data || res?.data?.obj,
          });
        } else
          reject({
            status: false,
            message: res?.data?.message,
          });
      })
      .catch((e) => {
        reject(e);
      });
  });
};
