import { useState } from "react"
import { CrossedEyeIcon } from "../../styles/svg/crossedEyeIcon"
import { getLang } from "../../helpers/localizationHelper"
import { EyeIcon } from "../../styles/svg/eyeIcon"

export const AppInput = ({ disable = false, className, label, placeholder, value, onChange, require = false, errorMessage, type = "text", isPassword = false }: { isPassword?: boolean, disable?: boolean, className?: string, type?: string, errorMessage: string, require?: boolean, label?: string, placeholder?: string, value: string, onChange: (val: any) => void }) => {
    const [showPassword, setShowPassword] = useState(isPassword)

    return <div style={{ position: 'relative' }} className={`col-xl-6 col-lg-6  mb-4 ${className}`}>

        {label &&
            <h4 className="card-title">
                {require ? <span style={{ color: 'red' }}> * </span> : null}{" "}
                {label}</h4>
        }


        <input readOnly={disable} value={value} onChange={(event) => {
            onChange?.(event?.target?.value)
        }} type={!!showPassword ? 'password' : "text"} className="form-control input-default mt-3" />
        {isPassword && <div onClick={() => { setShowPassword(prev => !prev) }} style={{ position: 'absolute', top: 40, right: getLang() == "ar-SA" ? undefined : 20, left: getLang() == "ar-SA" ? 20 : undefined }}>
            {!showPassword ? <CrossedEyeIcon color="gray" /> : <EyeIcon />
            }        </div>}

        {errorMessage && (
            <p style={{ marginTop: 5, fontSize: 12, marginBottom: 5, color: "red" }}>
                {errorMessage}
            </p>
        )}

    </div>
}