import Cookies from "universal-cookie";
import { ErrorToast } from "../../helpers/toastify";
import history from "../../routing/historyObj";

export const interceptorsErrorsResponse = (error) => {
  const cookies = new Cookies();
  switch (error?.response?.status) {
    case 422:
      return ErrorToast(error?.response?.data?.message);
    case 403:
      cookies.remove("token", { path: "/" });

      window.location.reload();
      return ErrorToast(error?.response?.data?.message);
    case 409:
      return ErrorToast(error?.response?.data?.message);
    case 400:
      return ErrorToast(error?.response?.data?.message);
    case 401:
      return ErrorToast(error?.response?.data?.message);
    case 404:
      return ErrorToast(error?.response?.data?.message);
    case 500:
      return ErrorToast(error?.response?.data?.message);
    case 502:
      return "";
    default:
      ErrorToast("Something Went Wrong");
  }
};
